export type EvidenceIntegrationConfig =
  | AwsRdsIntegrationConfig
  | CrowdstrikeAntiMalwareIntegrationConfig
  | GcpCloudSqlIntegrationConfig
  | JiraIssuesExportIntegrationConfig
  | QualysVulnerabilityIntegrationConfig
  | SharepointFileSyncIntegrationConfig
  | VeracodeVulnerabilityIntegrationConfig
  | OneloginSuperUsersIntegrationConfig
  | OneloginMfaIntegrationConfig
  | AwsObjectStorageIntegrationConfig
  | AwsGuarddutyIntegrationConfig
  | AwsInspectorIntegrationConfig
  | AwsIamIntegrationConfig
  | JumpcloudIntegrationConfig
  | AwsCloudwatchIntegrationConfig
  | AwsCloudtrailIntegrationConfig
  | AwsEcrIntegrationConfig
  | AwsWafIntegrationConfig
  | CrowdstrikeRemovableMediaIntegrationConfig
  | GithubVulnerabilityScanningReportIntegrationConfig;

export enum EvidenceIntegrationType {
  AntiMalware = 'anti-malware',
  Database = 'database',
  FileSync = 'file-sync',
  IssuesExport = 'issues-export',
  OldestChange = 'oldest-change',
  PageExport = 'page-export',
  Vulnerability = 'vulnerability',
  SuperUsers = 'super-users',
  Mfa = 'mfa',
  ObjectStorage = 'object-storage',
  Guardduty = 'guardduty',
  Inspector = 'inspector',
  IdentityAccessManagement = 'identity-access-management',
  MobileDeviceManagement = 'mobile-device-management',
  Cloudwatch = 'cloudwatch',
  Cloudtrail = 'cloudtrail',
  Ecr = 'ecr',
  Waf = 'waf',
  RemovableMedia = 'removable-media',
  VulnerabilityScanning = 'vulnerability-scanning',
}

export type AwsIntegrationAuth = {
  region: string;
  roleArn: string;
  externalId?: string;
};

export type AwsRdsIntegrationConfig = {
  type: EvidenceIntegrationType.Database;
  auth?: AwsIntegrationAuth;
  region?: string;
  dbInstanceIds?: string[];
  dbClusterIds?: string[];
  checks: DBIntegrationCheck[];
};

export enum DBIntegrationCheck {
  Encryption = 'encryption',
  MultiAZ = 'multiAZ',
}

export type AzureDatabaseIntegrationConfig = {
  type: EvidenceIntegrationType.Database;
  subscriptionId: string;
  checks: DBIntegrationCheck[];
  dbSelectors: AzureDatabaseSelector[];
};

export type AzureDatabaseSelector = {
  type: AzureDatabaseType;
  resourceGroup: string;
  name: string;
};

export enum AzureDatabaseType {
  SQLServer = 'azure.sql-server',
  SQLManagedInstance = 'azure.sql-managed-instance',
  MySQLFlexible = 'azure.mysql-flexible',
  PostgreSQLFlexible = 'azure.postgresql-flexible',
}

export type ConfluencePageExportIntegrationConfig = {
  type: EvidenceIntegrationType.PageExport;
  pageId: number;
};

export type CrowdstrikeAntiMalwareIntegrationConfig = {
  type: EvidenceIntegrationType.AntiMalware;
};

export type JiraIssuesExportIntegrationConfig = {
  type: EvidenceIntegrationType.IssuesExport;
  jqlQuery: string;
};

export type GcpCloudSqlIntegrationConfig = {
  type: EvidenceIntegrationType.Database;
  dbInstanceNames: string[];
  checks: DBIntegrationCheck[];
};

export type GcpFileSyncIntegrationConfig = {
  type: EvidenceIntegrationType.FileSync;
  fileId: string;
};

export type GithubOldestChangeIntegrationConfig = {
  type: EvidenceIntegrationType.OldestChange;
  repoName: string;
};

export type QualysVulnerabilityIntegrationConfig = {
  type: EvidenceIntegrationType.Vulnerability;
  webAppIds: number[];
};

export type SharepointFileSyncIntegrationConfig = {
  type: EvidenceIntegrationType.FileSync;
  driveId: string;
  filePath: string;
};

export type VeracodeVulnerabilityIntegrationConfig = {
  type: EvidenceIntegrationType.Vulnerability;
  businessUnit?: string;
  policy?: string;
  policyCompliance?: string;
  policyGuid?: string;
  scanStatus?: string[];
  scanType?: string;
  tag?: string;
  team?: string;
  buildId?: string;
  context?: string;
};

export type OneloginSuperUsersIntegrationConfig = {
  type: EvidenceIntegrationType.SuperUsers;
};

export type OneloginMfaIntegrationConfig = {
  type: EvidenceIntegrationType.Mfa;
};

export type AwsObjectStorageIntegrationConfig = {
  type: EvidenceIntegrationType.ObjectStorage;
  auth?: AwsIntegrationAuth;
  region?: string;
  buckets: string[];
  check: AwsObjectStorageCheck;
};

export enum AwsObjectStorageCheck {
  BlockPublicAccess = 'block-public-access',
  RequireSSL = 'require-ssl',
  Replication = 'replication',
  Encryption = 'encryption',
  Versioning = 'versioning',
  MFADelete = 'mfa-delete',
  ObjectLock = 'object-lock',
  Logging = 'logging',
}

export type AwsGuarddutyIntegrationConfig = {
  type: EvidenceIntegrationType.Guardduty;
  auth?: AwsIntegrationAuth;
  region?: string;
  checks: AwsGuarddutyCheck[];
};

export enum AwsGuarddutyCheckType {
  EnabledFeatures = 'enabled-features',
  SnsSubscription = 'sns-subscription',
  HighSeverityFindings = 'high-severity-findings',
}

export type AwsGuarddutyCheck = AGCGeneric | AGCEnabledFeatures;

export type AGCGeneric = {
  type: AwsGuarddutyCheckType.HighSeverityFindings | AwsGuarddutyCheckType.SnsSubscription;
};

export type AGCEnabledFeatures = {
  type: AwsGuarddutyCheckType.EnabledFeatures;
  features: Array<GuardDutyFeature>;
};

export enum GuardDutyFeature {
  S3DataEvents = 'S3_DATA_EVENTS',
  EksAuditLogs = 'EKS_AUDIT_LOGS',
  EbsMalwareProtection = 'EBS_MALWARE_PROTECTION',
  RdsLoginEvents = 'RDS_LOGIN_EVENTS',
  LambdaNetworkLogs = 'LAMBDA_NETWORK_LOGS',
  CloudTrail = 'CLOUD_TRAIL',
  DnsLogs = 'DNS_LOGS',
  FlowLogs = 'FLOW_LOGS',
  RmEksAddonManagement = 'RUNTIME_MONITORING.EKS_ADDON_MANAGEMENT',
  RmEcsFargateAgentManagement = 'RUNTIME_MONITORING.ECS_FARGATE_AGENT_MANAGEMENT',
  RmEc2AgentManagement = 'RUNTIME_MONITORING.EC2_AGENT_MANAGEMENT',
}

export type AwsInspectorIntegrationConfig = {
  type: EvidenceIntegrationType.Inspector;
  auth?: AwsIntegrationAuth;
  region?: string;
  checks: AwsInspectorCheck[];
};

export type AwsInspectorCheck = AInCGeneric | AInCServices;

export type AInCGeneric = {
  type: AwsInspectorCheckType.HighSeverityFindings;
};

export type AInCServices = {
  type: AwsInspectorCheckType.EnabledServices;
  services: Array<AwsInspectorService>;
};

export enum AwsInspectorCheckType {
  HighSeverityFindings = 'high-severity-findings',
  EnabledServices = 'enabled-services',
}

export enum AwsInspectorService {
  EC2 = 'ec2',
  ECR = 'ecr',
  Lambda = 'lambda',
  LambdaCode = 'lambda-code',
}

export type AwsIamIntegrationConfig = {
  type: EvidenceIntegrationType.IdentityAccessManagement;
  auth?: AwsIntegrationAuth;
  region?: string;
  checks: AwsIamCheck[];
};

export type AwsIamCheck =
  | AICAccessKeyRotation
  | AICGeneric
  | AICPasswordRotation
  | AICUnusedCredentials;

export type AICGeneric = {
  type:
    | AwsIamCheckType.ExpiredServerCertificate
    | AwsIamCheckType.ExternalAccessAnalyzer
    | AwsIamCheckType.MfaEnabledAllUsers
    | AwsIamCheckType.MfaEnabledConsoleAccess
    | AwsIamCheckType.NoAdminPrivileges
    | AwsIamCheckType.NoCloudShellFullAccess
    | AwsIamCheckType.NoPoliciesAttached
    | AwsIamCheckType.NoWildcardActionsForServices
    | AwsIamCheckType.PasswordPolicyConfiguration
    | AwsIamCheckType.SupportRole;
};

export type AICAccessKeyRotation = {
  type: AwsIamCheckType.AccessKeyRotation;
  rotationDays: number;
};

export type AICPasswordRotation = {
  type: AwsIamCheckType.PasswordRotation;
  rotationDays: number;
};

export type AICUnusedCredentials = {
  type: AwsIamCheckType.UnusedCredentials;
  inactiveDays: number;
};

export enum AwsIamCheckType {
  AccessKeyRotation = 'access-key-rotation',
  ExpiredServerCertificate = 'expired-server-certificate',
  ExternalAccessAnalyzer = 'external-access-analyzer',
  MfaEnabledAllUsers = 'mfa-enabled-all-users',
  MfaEnabledConsoleAccess = 'mfa-enabled-console-access',
  NoAdminPrivileges = 'no-admin-privileges',
  NoCloudShellFullAccess = 'no-cloud-shell-full-access',
  NoPoliciesAttached = 'no-policies-attached',
  NoWildcardActionsForServices = 'no-wildcard-actions-for-services',
  PasswordPolicyConfiguration = 'password-policy-configuration',
  PasswordRotation = 'password-rotation',
  SupportRole = 'support-role',
  UnusedCredentials = 'unused-credentials',
}

export type JumpcloudIntegrationConfig = {
  type: EvidenceIntegrationType.MobileDeviceManagement;
  checks: JumpcloudMdmCheck[];
};

export type JumpcloudMdmCheck =
  | JMCFullDiskEncryption
  | JMCPatchPolicy
  | JMCMfaEnrollment
  | JMCActiveFirewall;

export type JMCFullDiskEncryption = {
  type: JumpcloudMdmCheckType.FullDiskEncryption;
  excludeSystems?: string[];
};

export type JMCPatchPolicy = {
  type: JumpcloudMdmCheckType.PatchPolicy;
  excludeSystems?: string[];
};

export type JMCMfaEnrollment = {
  type: JumpcloudMdmCheckType.MfaEnrollment;
  excludeSystems?: string[];
  excludeUsers?: string[];
};

export type JMCActiveFirewall = {
  type: JumpcloudMdmCheckType.ActiveFirewall;
  excludeSystems?: string[];
};

export enum JumpcloudMdmCheckType {
  FullDiskEncryption = 'full-disk-encryption',
  PatchPolicy = 'patch-policy',
  MfaEnrollment = 'mfa-enrollment',
  ActiveFirewall = 'active-firewall',
}

export type AwsCloudwatchIntegrationConfig = {
  type: EvidenceIntegrationType.Cloudwatch;
  auth?: AwsIntegrationAuth;
  region?: string;
  checks: AwsCloudwatchCheck[];
};

export type AwsCloudwatchCheck =
  | CWGeneric
  | CWAlarmActionsConfigured
  | CWLogGroupsRetention
  | CWLogMetricFilters;

export type CWAlarmActionsConfigured = {
  type: AwsCloudwatchCheckType.AlarmActionsConfigured;
  insufficientDataActionRequired?: boolean;
  okActionRequired?: boolean;
};

export type CWGeneric = {
  type: AwsCloudwatchCheckType.AlarmActionsActivated;
};

export type CWLogGroupsRetention = {
  type: AwsCloudwatchCheckType.LogGroupsRetention;
  retentionTimeInDays: number;
  logGroupPrefixes?: string[];
};

export type CWLogMetricFilters = {
  type: AwsCloudwatchCheckType.LogMetricFilter;
  checks: Array<LogMetricFilterCheck>;
};

export enum AwsCloudwatchCheckType {
  AlarmActionsConfigured = 'alarm-actions-configured',
  AlarmActionsActivated = 'alarm-actions-activated',
  LogGroupsRetention = 'log-groups-retention',
  LogMetricFilter = 'log-metric-filter',
}

export enum LogMetricFilterCheck {
  RootUserUsage = 'root-user-usage',
  UnauthorizedApiCalls = 'unauthorized-api-calls',
  ManagementConsoleSignIn = 'management-console-sign-in',
  IamPolicyChanges = 'iam-policy-changes',
  CloudtrailConfigurationChanges = 'cloudtrail-configuration-changes',
  ConsoleAuthenticationFailures = 'console-authentication-failures',
  CustomerKeysDeletion = 'customer-keys-deletion',
  S3PolicyChanges = 's3-policy-changes',
  AwsConfigChanges = 'aws-config-changes',
  SecurityGroupChanges = 'security-group-changes',
  NetworkAclChanges = 'network-acl-changes',
  NetworkGatewayChanges = 'network-gateway-changes',
  RouteTableChanges = 'route-table-changes',
  VpcChanges = 'vpc-changes',
}

export type AwsCloudtrailIntegrationConfig = {
  type: EvidenceIntegrationType.Cloudtrail;
  auth?: AwsIntegrationAuth;
  region?: string;
  checks: AwsCloudtrailCheck[];
};

export type AwsCloudtrailCheck = {
  type:
    | AwsCloudtrailCheckType.MultiRegionTrail
    | AwsCloudtrailCheckType.TrailEnabled
    | AwsCloudtrailCheckType.LogFileValidation
    | AwsCloudtrailCheckType.TrailEncryption
    | AwsCloudtrailCheckType.LogsIntegration;
};

export enum AwsCloudtrailCheckType {
  MultiRegionTrail = 'multi-region-trail',
  TrailEnabled = 'trail-enabled',
  LogFileValidation = 'log-file-validation',
  TrailEncryption = 'trail-encryption',
  LogsIntegration = 'logs-integration',
}

export type AwsEcrIntegrationConfig = {
  type: EvidenceIntegrationType.Ecr;
  auth?: AwsIntegrationAuth;
  region?: string;
  checks: AwsEcrCheck[];
};

export type AwsEcrCheck = ImageScanningConfigured;

export type ImageScanningConfigured = {
  type: AwsEcrCheckType.ImageScanningConfigured;
  repositoryNames: string[];
};

export enum AwsEcrCheckType {
  ImageScanningConfigured = 'image-scanning-configured',
}

export type AwsWafIntegrationConfig = {
  type: EvidenceIntegrationType.Waf;
  auth?: AwsIntegrationAuth;
  region?: string;
  checks: AwsWafCheck[];
};

export type AwsWafCheck = WebAclAssociation;

export type WebAclAssociation = {
  type: AwsWafCheckType.WebAclAssociation;
  resourceArns: string[];
  blockActionRequired?: boolean;
};

export enum AwsWafCheckType {
  WebAclAssociation = 'web-acl-association',
}
export type CrowdstrikeRemovableMediaIntegrationConfig = {
  type: EvidenceIntegrationType.RemovableMedia;
  requiredPolicyIds?: string[];
  excludeHosts?: string[];
};

export type GithubVulnerabilityScanningReportIntegrationConfig = {
  type: EvidenceIntegrationType.VulnerabilityScanning;
  checks: GithubVulnerabilityScanningCheck[];
};

export type GithubVulnerabilityScanningCheck =
  | VulnerabilityScanningStatus
  | CriticalVulnerabilityCount;

export type VulnerabilityScanningStatus = {
  type: GithubVulnerabilityScanningCheckType.VulnerabilityScanningStatus;
  repositoryNames: string[];
};

export type CriticalVulnerabilityCount = {
  type: GithubVulnerabilityScanningCheckType.CriticalVulnerabilityCount;
  repositoryNames: string[];
};

export enum GithubVulnerabilityScanningCheckType {
  VulnerabilityScanningStatus = 'vulnerability-scanning-status',
  CriticalVulnerabilityCount = 'critical-vulnerability-count',
}
