import { Text, useColorModeValue } from '@chakra-ui/react';
import {
  Notification_Types_Enum,
  Vendor_Questionnaire_Statuses_Enum,
} from '@main/graphql/types.generated';
import { Trans } from '@main/ui';
import { useTranslation } from 'react-i18next';

import { router } from '../../../router';
import {
  AssignedNotificationAdapter,
  AssignedNotificationData,
  AssignedNotificationHandler,
} from '../../notifications/assigned-notification-handler';
import {
  NotificationContentComponent,
  NotificationContentProps,
  NotificationHandler,
  NotificationItem,
} from '../../notifications/notification-handler';

export interface VendorQuestionnaireNotification extends NotificationItem {
  content: NotificationItem['content'] & {
    notifications_vendor_questionnaire: NonNullable<
      NotificationItem['content']['notifications_vendor_questionnaire']
    >;
  };
}

function isVQNotification(
  notification: NotificationItem,
): notification is VendorQuestionnaireNotification {
  return !!notification.content.notifications_vendor_questionnaire;
}

export interface VendorQuestionnaireOverdueNotification extends VendorQuestionnaireNotification {
  content: VendorQuestionnaireNotification['content'] & {
    params: { questionnaireStatus: Vendor_Questionnaire_Statuses_Enum };
  };
}

export class VendorQuestionnaireOverdueNotificationHandler
  implements NotificationHandler<VendorQuestionnaireOverdueNotification>
{
  static readonly type = Notification_Types_Enum.QuestionnaireStatusOverdue;

  static canHandle(
    notification: NotificationItem,
  ): notification is VendorQuestionnaireOverdueNotification {
    return isVQNotification(notification) && 'questionnaireStatus' in notification.content.params;
  }

  static create() {
    return new this();
  }

  getContentComponent(): NotificationContentComponent<
    VendorQuestionnaireOverdueNotification,
    this
  > {
    return VendorQuestionnaireOverdueNotificationContent;
  }

  redirect(notification: VendorQuestionnaireOverdueNotification) {
    router.navigate({
      to: '/vendors',
      search: {
        activeTab: 'questionnaires',
        drawerEntity: 'vendor',
        drawerEntityId: notification.content.notifications_vendor_questionnaire.vendor.id,
      },
    });
  }
}

export class VendorQuestionnaireSubmittedNotificationHandler
  implements NotificationHandler<VendorQuestionnaireNotification>
{
  static readonly type = Notification_Types_Enum.VendorQuestionnaireSubmission;

  static canHandle = isVQNotification;

  static create() {
    return new this();
  }

  getContentComponent(): NotificationContentComponent<VendorQuestionnaireNotification, this> {
    return VendorQuestionnaireSubmittedNotificationContent;
  }

  redirect(notification: VendorQuestionnaireNotification) {
    router.navigate({
      to: '/vendors',
      search: {
        drawerEntity: 'vendor-questionnaire',
        drawerEntityId: notification.content.notifications_vendor_questionnaire.id,
        activeTab: 'submissions',
      },
    });
  }
}

export interface VendorQuestionnaireAiFindingNotification extends VendorQuestionnaireNotification {
  content: VendorQuestionnaireNotification['content'] & {
    params: { vqAfterSubmissionEvent: 'vq-ai-review-findings-found' };
  };
}

export class VendorQuestionnaireAiFindingNotificationHandler
  implements NotificationHandler<VendorQuestionnaireAiFindingNotification>
{
  static readonly type = Notification_Types_Enum.VendorQuestionnaireSubmission;

  static canHandle(
    notification: NotificationItem,
  ): notification is VendorQuestionnaireAiFindingNotification {
    return (
      isVQNotification(notification) &&
      'vqAfterSubmissionEvent' in notification.content.params &&
      notification.content.params.vqAfterSubmissionEvent === 'vq-ai-review-findings-found'
    );
  }

  static create() {
    return new this();
  }

  getContentComponent(): NotificationContentComponent<
    VendorQuestionnaireAiFindingNotification,
    this
  > {
    return VendorQuestionnaireAiFindingNotificationContent;
  }

  redirect(notification: VendorQuestionnaireAiFindingNotification) {
    router.navigate({
      to: '/vendors',
      search: {
        drawerEntity: 'vendor-questionnaire',
        drawerEntityId: notification.content.notifications_vendor_questionnaire.id,
        activeTab: 'findings',
      },
    });
  }
}

export interface MentionedInVQCommentNotification extends NotificationItem {
  content: NotificationItem['content'] & {
    notifications_comment: NonNullable<NotificationItem['content']['notifications_comment']> & {
      vendor_questionnaire: NonNullable<
        NonNullable<NotificationItem['content']['notifications_comment']>['vendor_questionnaire']
      >;
    };
  };
}

export class MentionedInVQCommentNotificationHandler
  implements AssignedNotificationAdapter<MentionedInVQCommentNotification>
{
  static readonly type = Notification_Types_Enum.MentionedInComment;

  static canHandle(
    notification: NotificationItem,
  ): notification is MentionedInVQCommentNotification {
    return !!notification.content.notifications_comment?.vendor_questionnaire;
  }

  static create() {
    return new AssignedNotificationHandler(new this());
  }

  getAssignedData(notification: MentionedInVQCommentNotification): AssignedNotificationData {
    return {
      internalId:
        notification.content.notifications_comment.vendor_questionnaire.questionnaire.internal_id,
      entityName:
        notification.content.notifications_comment.vendor_questionnaire.questionnaire.name,
      content: 'notification.vendorQuestionnaire.mentioned',
    };
  }

  redirect(notification: MentionedInVQCommentNotification): void {
    router.navigate({
      to: '/questionnaires',
      search: {
        drawerEntity: 'vendor-questionnaire',
        drawerEntityId: notification.content.notifications_comment.vendor_questionnaire.id,
        activeTab: 'comments',
      },
    });
  }
}

function VendorQuestionnaireOverdueNotificationContent({
  notification,
}: NotificationContentProps<VendorQuestionnaireOverdueNotification>) {
  const { t } = useTranslation();
  const notificationTextColor = useColorModeValue('gray.600', 'gray.300');
  const questionnaireStatus = t(
    `vendors.questionnaires.status.${notification.content.params.questionnaireStatus}`,
  ).toLowerCase();
  const questionnaireName =
    notification.content.notifications_vendor_questionnaire.questionnaire.name;
  const vendorName = notification.content.notifications_vendor_questionnaire.vendor.name;

  return (
    <Text fontSize="sm" textColor={notificationTextColor}>
      <Trans
        i18nKey="notification.vendorQuestionnaireStatus"
        values={{ questionnaireName, vendorName, questionnaireStatus }}
      />
    </Text>
  );
}

function VendorQuestionnaireSubmittedNotificationContent({
  notification,
}: NotificationContentProps<VendorQuestionnaireNotification>) {
  const notificationTextColor = useColorModeValue('gray.600', 'gray.300');

  return (
    <Text fontSize="sm" textColor={notificationTextColor}>
      <Trans
        i18nKey="notification.vendorQuestionnaireSubmission"
        values={{
          questionnaireName:
            notification.content.notifications_vendor_questionnaire.questionnaire.name,
          vendorEmail: notification.content.notifications_vendor_questionnaire.sent_to_email,
          vendorName: notification.content.notifications_vendor_questionnaire.vendor.name,
        }}
      />
    </Text>
  );
}

function VendorQuestionnaireAiFindingNotificationContent({
  notification,
}: NotificationContentProps<VendorQuestionnaireAiFindingNotification>) {
  const notificationTextColor = useColorModeValue('gray.600', 'gray.300');

  return (
    <Text fontSize="sm" textColor={notificationTextColor}>
      <Trans
        i18nKey="notification.vendorQuestionnaireHasAIReviewFindings"
        values={{
          questionnaireName:
            notification.content.notifications_vendor_questionnaire.questionnaire.name,
          vendorName: notification.content.notifications_vendor_questionnaire.vendor.name,
        }}
      />
    </Text>
  );
}
