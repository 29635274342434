import { Icon, IconButton } from '@chakra-ui/react';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { Field_Entities_Enum } from '@main/graphql/types.generated';
import { isNonNullable } from '@main/shared/utils';
import {
  createColumnHelper,
  Table,
  useDrawer,
  useTableFiltersQuery,
  useTableSearchQuery,
  useTableSortQuery,
} from '@main/ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { customFieldColumn } from '../custom-fields/custom-field-column';
import { useGetFieldConfigsQuery } from '../custom-fields/field-config';
import { getCurrentUserSelectedOrg } from '../user/slice';
import { useDeleteVendor } from './delete-vendor';
import { useGetVendorsQuery } from './get-vendor.generated';
import { getMappedVendors, OrganizationVendor } from './slice';
import { useVendorColumnHelper } from './table-columns';
import { useVendorsTableSettings } from './table-settings';
import { useLazyToggleVendorTerminateAction } from './use-drawer-actions';

export const VendorsTable = () => {
  const { t } = useTranslation();
  const organization = useAppSelector(getCurrentUserSelectedOrg);

  const { isLoading: isVendorLoading } = useGetVendorsQuery({
    organization_id: organization.id,
  });
  const vendors = useAppSelector(getMappedVendors);
  const { columnVisibility, setColumnVisibility } = useVendorsTableSettings(
    'vendors:table:column-visibility',
  );
  const columns = useVendorsTableColumns();
  const [columnFilters, setColumnFilters] = useTableFiltersQuery({
    columns,
    searchParam: 'vendorsFilter',
  });
  const [globalFilter, setGlobalFilter] = useTableSearchQuery({ searchParam: 'search' });
  const [sorting, setSorting] = useTableSortQuery({ searchParam: 'vendorsSort' });
  const tableItemName = useMemo(() => {
    return {
      singular: t('entities.vendor').toLowerCase(),
      plural: t('entities.plural.vendors').toLowerCase(),
    };
  }, [t]);

  return (
    <Table
      minW="900px"
      entity="vendor"
      data={vendors}
      isLoading={isVendorLoading}
      columns={columns}
      itemName={tableItemName}
      pageSize={15}
      columnFilters={columnFilters}
      onColumnFiltersChange={setColumnFilters}
      globalFilter={globalFilter}
      onGlobalFilterChange={setGlobalFilter}
      columnVisibility={columnVisibility}
      onColumnVisibilityChange={setColumnVisibility}
      sorting={sorting}
      onSortingChange={setSorting}
    />
  );
};

function useVendorsTableColumns() {
  const { t } = useTranslation();
  const drawer = useDrawer();

  const organization = useAppSelector(getCurrentUserSelectedOrg);
  const fieldConfigsQuery = useGetFieldConfigsQuery({
    orgId: organization.id,
    entityName: Field_Entities_Enum.Vendor,
  });
  const fieldConfigs = fieldConfigsQuery.data?.field_configs;
  const toggleTerminateVendorAction = useLazyToggleVendorTerminateAction();

  const deleteVendor = useDeleteVendor();

  const vendorColumnHelper = useVendorColumnHelper();

  return useMemo(() => {
    const columnHelper = createColumnHelper<OrganizationVendor>();
    const openVendorDrawer = (vendorId: string) =>
      drawer.open({ entity: 'vendor', entityId: vendorId });

    return [
      vendorColumnHelper.status(),
      vendorColumnHelper.internalId(),
      vendorColumnHelper.name({ onClickOpenDrawer: true }),
      vendorColumnHelper.lastReviewedDate(),
      vendorColumnHelper.description(),
      vendorColumnHelper.riskLevel({ isEditable: true }),
      vendorColumnHelper.tier({ isEditable: true }),
      vendorColumnHelper.approvalStatus({ isEditable: true }),
      vendorColumnHelper.questionnaireStatus(),
      vendorColumnHelper.owner({ isEditable: true }),
      vendorColumnHelper.frequency(),
      vendorColumnHelper.findings(),

      ...(fieldConfigs || []).map(customFieldColumn<OrganizationVendor>),

      columnHelper.columns.actions({
        size: 70,
        PrimaryAction: (context) => (
          <IconButton
            minW={4}
            variant="link"
            aria-label={t('vendors.actions.edit')}
            icon={<PencilIcon />}
            onClick={() => openVendorDrawer(context.row.original.id)}
          />
        ),
        secondaryActions: ({ row }) =>
          [
            toggleTerminateVendorAction({
              vendorId: row.original.id,
              status: row.original.assessment_status,
            }),
            deleteVendor && {
              icon: <Icon as={TrashIcon} />,
              label: t('buttons.delete'),
              onClick: () => {
                deleteVendor(row.original.id);
              },
            },
          ].filter(isNonNullable),
      }),
    ];
  }, [vendorColumnHelper, fieldConfigs, drawer, t, toggleTerminateVendorAction, deleteVendor]);
}
