import { useColorModeValue } from '@chakra-ui/react';
import { DrawerProperty, EditableAutoResizeTextarea } from '@main/ui';
import { BarsIcon } from '@main/ui/icons';
import { useTranslation } from 'react-i18next';

export const TextFieldInput = ({
  name,
  value,
  onChange,
  isReadOnly,
}: {
  name: string;
  value?: string;
  onChange: (value: string) => void;
  isReadOnly?: boolean;
}) => {
  const { t } = useTranslation('ui');
  const textColor = useColorModeValue('gray.600', 'gray.500');

  return (
    <DrawerProperty isReadOnly={isReadOnly}>
      <DrawerProperty.Label icon={BarsIcon}>{name}</DrawerProperty.Label>
      <DrawerProperty.Content>
        <EditableAutoResizeTextarea
          placeholder={
            isReadOnly
              ? t('customFields.placeholder.hasNoPermission')
              : t('customFields.placeholder.textField')
          }
          defaultValue={value}
          color={textColor}
          onSubmit={(newValue) => {
            if (newValue !== value) {
              onChange(newValue);
            }
          }}
        />
      </DrawerProperty.Content>
    </DrawerProperty>
  );
};
