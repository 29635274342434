import { Box, Flex, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { ArrowPathRoundedSquareIcon, TrashIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { useAiCreateEntityMutation } from '@main/graphql/features/AiCreateEntity.generated';
import { isNonNullable, severityColorScheme, toError } from '@main/shared/utils';
import { errorToast, FindingCard, StatusTag, successToast, useAlertDialog } from '@main/ui';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { CreateFindingRiskButton } from '../findings/create-risk';
import { CreateFindingTaskButton } from '../findings/create-task';
import {
  useDeleteManualFindingMutation,
  useToggleControlIgnoreFindingMutation,
} from '../findings/ManageFindings.generated';
import { FindingMenuActions } from '../findings/menu-actions';
import { TabFooter } from '../shared/tab-footer';
import { getCurrentUserSelectedOrgRole } from '../user/slice';
import { ControlFindingContent } from './finding/content';
import { ControlFinding } from './finding/control-finding';
import { api as getControlApi } from './get-control.generated';
import { getControlFindings } from './slice';

const useFindingMenuActions = ({
  controlId,
  controlFinding,
}: {
  controlId: string;
  controlFinding: ControlFinding;
}) => {
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canUpdateControl = userRole.permissionMap?.update_controls;
  const { t } = useTranslation();
  const { toggleIgnoreState, deleteManualFinding } = useFindingActionsHandlers(controlId);
  const isIgnored = !!controlFinding.finding.ignored_at;
  const { openDialog } = useAlertDialog();

  const toggleIgnoreStateAction = canUpdateControl
    ? {
        icon: isIgnored ? <ArrowPathRoundedSquareIcon /> : <XCircleIcon />,
        label: isIgnored ? t('findings.card.reinstateBtn') : t('findings.card.ignoreBtn'),
        onClick: () => {
          toggleIgnoreState({ findingId: controlFinding.finding.id, isIgnored });
        },
      }
    : undefined;

  const manualFindingId = controlFinding.finding.manual_finding?.id;
  const deleteManualFindingAction =
    canUpdateControl && manualFindingId
      ? {
          icon: <TrashIcon />,
          label: t('buttons.delete'),
          onClick: () => {
            openDialog({
              dialogHeader: t('controls.findings.alert.delete.header'),
              dialogContent: t('controls.findings.alert.delete.content'),
              confirmAction: {
                children: t('controls.findings.alert.delete.confirm'),
                onClick: () => deleteManualFinding(manualFindingId),
              },
            });
          },
        }
      : undefined;

  return {
    menuActions: [toggleIgnoreStateAction, deleteManualFindingAction].filter(isNonNullable),
  };
};

const ControlFindingCard = ({
  controlFinding,
  controlId,
  index,
}: {
  controlFinding: ControlFinding;
  controlId: string;
  index: number;
}) => {
  const { t } = useTranslation();
  const { menuActions } = useFindingMenuActions({
    controlId,
    controlFinding,
  });
  const [aiCreateEntity] = useAiCreateEntityMutation();
  const { findings } = useAppSelector((state) => getControlFindings(state, controlId));
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canUpdateControl = userRole.permissionMap?.update_controls;
  const canCreateRisk = userRole.permissionMap?.write_risks;

  const isIgnored = !!controlFinding.finding.ignored_at;
  const riskId = controlFinding.finding.risk_id;
  const taskId = controlFinding.finding.task_id;

  return (
    <FindingCard key={controlFinding.finding.id} isIgnored={isIgnored}>
      <FindingCard.Header
        heading={t('findings.card.findingsNo', {
          number: index + 1,
          total: findings.length,
        })}
      >
        <StatusTag colorScheme={severityColorScheme(controlFinding.severity)} size="sm">
          {t(`findings.type.${controlFinding.finding.type}`)}
        </StatusTag>
      </FindingCard.Header>

      <FindingCard.Actions>
        {canCreateRisk && (
          <CreateFindingRiskButton
            riskId={riskId}
            onCreate={() =>
              aiCreateEntity({
                input: { entity: 'risk-control-finding', findingId: controlFinding.finding.id },
              })
            }
          />
        )}
        {canUpdateControl && (
          <CreateFindingTaskButton
            taskId={taskId}
            onCreate={() =>
              aiCreateEntity({
                input: { entity: 'task-control-finding', findingId: controlFinding.finding.id },
              })
            }
          />
        )}

        <FindingMenuActions actions={menuActions} />
      </FindingCard.Actions>

      <FindingCard.Body>
        <ControlFindingContent {...controlFinding} />
      </FindingCard.Body>
    </FindingCard>
  );
};

export const ControlFindingsTab = ({ controlId }: { controlId: string }) => {
  const { t } = useTranslation();
  const headingColor = useColorModeValue('gray.700', 'gray.300');
  const { findings } = useAppSelector((state) => getControlFindings(state, controlId));

  return (
    <Box>
      <Flex direction="column" gap={6} paddingBottom="40px">
        <Text color={headingColor}>{t('controls.findings.description')}</Text>
        <Stack>
          {findings.map((controlFinding, index) => (
            <ControlFindingCard
              key={controlFinding.finding.id}
              index={index}
              controlFinding={controlFinding}
              controlId={controlId}
            />
          ))}
        </Stack>
      </Flex>
      <TabFooter>
        <Text fontSize="xs" color="gray.400">
          {t('controls.findings.tip')}
        </Text>
      </TabFooter>
    </Box>
  );
};

const useFindingActionsHandlers = (controlId: string) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [toggleIgnoreFinding] = useToggleControlIgnoreFindingMutation();
  const [deleteManualFindingAction] = useDeleteManualFindingMutation();

  const toggleIgnoreState = async ({
    findingId,
    isIgnored,
  }: {
    findingId: string;
    isIgnored: boolean;
  }) => {
    const patchResult = dispatch(
      getControlApi.util.updateQueryData('GetControl', { controlId }, (draft) => {
        for (const draftFinding of draft.controls_by_pk?.findings ?? []) {
          if (draftFinding.id === findingId) {
            draftFinding.ignored_at = isIgnored ? undefined : new Date().toISOString();
          }
        }
      }),
    );
    try {
      await toggleIgnoreFinding({
        findingId: findingId,
        ignored_at: isIgnored ? null : 'now()',
      }).unwrap();
    } catch (error) {
      errorToast(t('errorMessages.updateFailed', { entity: t('entities.finding') }));
      datadogLogs.logger.error('Toggling ignore state of the finding failed', {}, toError(error));
      patchResult.undo();
    }
  };

  const deleteManualFinding = async (findingId: string) => {
    const patchResult = dispatch(
      getControlApi.util.updateQueryData('GetControl', { controlId }, (draft) => {
        const draftFindings = draft.controls_by_pk?.findings ?? [];

        for (const [idx, draftFinding] of draftFindings.entries()) {
          if (draftFinding.id === findingId) {
            draftFindings.splice(idx, 1);
          }
        }
      }),
    );
    try {
      await deleteManualFindingAction({ findingId }).unwrap();
      successToast(t('successMessages.deleteSucceeded', { entity: t('entities.finding') }));
    } catch (error) {
      patchResult.undo();
      errorToast(t('errorMessages.deleteFailed', { entity: t('entities.finding') }));
      datadogLogs.logger.error('deleting manual finding failed', {}, toError(error));
    }
  };

  return {
    toggleIgnoreState,
    deleteManualFinding,
  };
};
