import type {
  DynamicFormFieldConfig,
  QuestionnaireFormComponentRegistry,
} from '@main/dynamic-form';

import type {
  Client_Questionnaire_Status_Enum,
  Comments,
  Control_Evidences,
  Controls,
  Policies,
  Policy_Acknowledgement_Statuses_Enum,
  Policy_Approval_Statuses_Enum,
  Policy_Approval_Users,
  Policy_Approvals,
  Policy_Versions,
  Reviews,
  Risk_Controls,
  Risks,
  Vendor_Questionnaire_Statuses_Enum,
  Vendor_Questionnaires,
  Vendor_Risks,
  Vendors,
} from './types.generated';

export type { ColumnFiltersState, SortingState } from '@tanstack/react-table';

export * from './integration-configs';

declare module 'graphql' {
  interface GraphQLErrorExtensions {
    code?: GraphqlExtensionsCode;
  }
}

export type UserMetaData = Partial<{
  firstName: string;
  lastName: string;
  isOnboardingFinished: boolean;
}>;

type Properties = 'category' | 'group' | 'criteria';
export type ControlTags = Array<Record<Properties, string[]>>;

export enum ControlEvidenceStatus {
  CALCULATING = 'Calculating',
  VALID = 'Valid',
  INVALID = 'Invalid',
  AT_RISK = 'At risk',
}

export enum ControlStatus {
  PENDING = 'Pending',
  NOT_RELEVANT = 'Not relevant',
  VALID = 'Valid',
  AT_RISK = 'At risk',
  FAILING = 'Failing',
}

export enum TaskStatus {
  PENDING = 'Pending',
  IN_PROGRESS = 'In progress',
  COMPLETED = 'Completed',
  OVERDUE = 'Overdue',
}

export enum VendorAssessmentStatus {
  PENDING = 'Pending',
  REVIEWED = 'Reviewed',
  EXPIRING = 'Expiring',
  OVERDUE = 'Overdue',
  TERMINATED = 'Terminated',
}

export enum RiskAssessmentStatus {
  PENDING = 'Pending',
  REVIEWED = 'Reviewed',
  EXPIRING = 'Expiring',
  OVERDUE = 'Overdue',
  CLOSED = 'Closed',
}

export enum EvidenceStatus {
  PENDING = 'Pending',
  VALID = 'Valid',
  AT_RISK = 'At risk',
  INVALID = 'Invalid',
}

export type RiskAuditRow =
  | {
      table_name: 'risks';
      row_data: Risks;
      changed_fields: Risks & { last_reviewed_date: string | undefined };
    }
  | { table_name: 'risk_controls'; row_data: Risk_Controls; changed_fields: Risk_Controls }
  | {
      table_name: 'comments';
      row_data: Comments;
      changed_fields: Comments;
    }
  | {
      table_name: 'reviews';
      row_data: Reviews;
      changed_fields: Reviews;
    };
export type RiskRowData = RiskAuditRow['row_data'];
export type RiskHistoryTables = RiskAuditRow['table_name'];
export type RiskChangedFields = RiskAuditRow['changed_fields'];

export type ControlAuditRow =
  | { table_name: 'controls'; row_data: Controls; changed_fields: Controls }
  | {
      table_name: 'control_evidences';
      row_data: Control_Evidences;
      changed_fields: Control_Evidences;
    }
  | {
      table_name: 'comments';
      row_data: Comments;
      changed_fields: Comments;
    };
export type ControlRowData = ControlAuditRow['row_data'];
export type ControlHistoryTables = ControlAuditRow['table_name'];
export type ControlChangedFields = ControlAuditRow['changed_fields'];

export type PolicyAuditRow =
  | { table_name: 'policies'; row_data: Policies; changed_fields: Policies }
  | {
      table_name: 'policy_versions';
      row_data: Policy_Versions;
      changed_fields: Policy_Versions;
    }
  | {
      table_name: 'policy_approvals';
      row_data: Policy_Approvals;
      changed_fields: Policy_Approvals;
    }
  | {
      table_name: 'policy_approval_users';
      row_data: Policy_Approval_Users;
      changed_fields: Policy_Approval_Users;
    }
  | {
      table_name: 'comments';
      row_data: Comments;
      changed_fields: Comments;
    };
export type PolicyRowData = PolicyAuditRow['row_data'];
export type PolicyHistoryTables = PolicyAuditRow['table_name'];
export type PolicyChangedFields = PolicyAuditRow['changed_fields'];

export type VendorAuditRow =
  | {
      table_name: 'vendors';
      row_data: Vendors;
      /* Extending the Type to preserve deprecated column for the Vendors */
      changed_fields: Vendors & { last_reviewed_date: string | undefined };
    }
  | {
      table_name: 'vendor_questionnaires';
      row_data: Vendor_Questionnaires;
      changed_fields: Vendor_Questionnaires;
    }
  | {
      table_name: 'vendor_risks';
      row_data: Vendor_Risks;
      changed_fields: Vendor_Risks;
    }
  | {
      table_name: 'comments';
      row_data: Comments;
      changed_fields: Comments;
    }
  | {
      table_name: 'reviews';
      row_data: Reviews;
      changed_fields: Reviews;
    };
export type VendorRowData = VendorAuditRow['row_data'];
export type VendorHistoryTables = VendorAuditRow['table_name'];
export type VendorChangedFields = VendorAuditRow['changed_fields'];

export type AuditTableHasuraUser = {
  'x-hasura-user-id': string;
  'x-hasura-impersonater-id'?: string;
} & (
  | {
      'x-hasura-role': 'user';
    }
  | { 'x-hasura-role': 'admin' }
);

export type AuditTableActions = 'I' | 'U' | 'D' | 'T';

export type GraphqlExtensionsCode =
  | 'constraint-violation'
  | 'USER_ALREADY_EXISTS'
  | 'USER_ALREADY_INVITED'
  | 'INVALID_INPUT'
  | 'NOT_FOUND'
  | 'DUPLICATE'
  | 'FORBIDDEN'
  | 'CONFLICT'
  | 'access-denied'
  | 'invalid-jwt'
  | 'unexpected';

export type FormConfig = DynamicFormFieldConfig<QuestionnaireFormComponentRegistry>[];

export type TaskNotificationParams = {
  taskStatus?: TaskStatus;
  assigneeId?: string;
};

export type NotificationParams =
  | {
      assignorId: string;
      type?: 'acknowledgement-request' | 'approval-request';
    }
  | { controlStatus: ControlStatus }
  | {
      riskStatus: RiskAssessmentStatus;
    }
  | {
      vendorStatus: VendorAssessmentStatus;
    }
  | {
      questionnaireStatus: Vendor_Questionnaire_Statuses_Enum;
    }
  | TaskNotificationParams
  | {
      policyApprovalStatus:
        | Policy_Approval_Statuses_Enum.Expiring
        | Policy_Approval_Statuses_Enum.Overdue
        | Policy_Approval_Statuses_Enum.Approved;
      approvalReminderTo?: 'approval_creator';
    }
  | {
      policyAcknowledgementStatus:
        | Policy_Acknowledgement_Statuses_Enum.Expiring
        | Policy_Acknowledgement_Statuses_Enum.Overdue;
    }
  | {
      vendorQuestionnaireStatus: Vendor_Questionnaire_Statuses_Enum;
    }
  | {
      vqAfterSubmissionEvent?: 'vq-ai-review-findings-found';
    }
  | {
      clientQuestionnaireStatus: Client_Questionnaire_Status_Enum;
    };

export type AiAutocompleteEntities =
  | 'risk-treatment-plan'
  | 'control-description'
  | 'risk-description'
  | 'clientq-question-answer';

export interface AiAutocompleteEntityOutput<
  TEntity extends AiAutocompleteEntities = AiAutocompleteEntities,
> {
  autocomplete: string;
  extra: AiAutocompleteEntityOutputExtra<TEntity>;
}

export interface AiAutocompleteEntityOutputExtras {
  'clientq-question-answer': { comment: string };
}

export type AiAutocompleteEntityOutputExtra<
  TEntity extends AiAutocompleteEntities = AiAutocompleteEntities,
> = TEntity extends keyof AiAutocompleteEntityOutputExtras
  ? AiAutocompleteEntityOutputExtras[TEntity]
  : undefined;

export type AiCreateEntityInput =
  | AiCreateTaskFromControlFindingInput
  | AiCreateRiskFromControlFindingInput
  | AiCreateTaskFromVendorFindingInput
  | AiCreateRiskFromVendorFindingInput;

export interface AiCreateTaskFromControlFindingInput {
  entity: 'task-control-finding';
  findingId: string;
}

export interface AiCreateRiskFromControlFindingInput {
  entity: 'risk-control-finding';
  findingId: string;
}

export interface AiCreateTaskFromVendorFindingInput {
  entity: 'task-vendor-finding';
  findingId: string;
}

export interface AiCreateRiskFromVendorFindingInput {
  entity: 'risk-vendor-finding';
  findingId: string;
}

export type VendorQuestionnaireFormAnswerValue = string | string[];

export interface ClientQquestionMetadata {
  row: number;
  page: null;
  page_name: null;
  answers_header: string;
  comments_header: string;
  possible_answers: string | string[];
  questions_header: string;
  answers_coordinates: [number, number];
  comments_coordinates: [number, number];
  question_coordinates: [number, number];
}
