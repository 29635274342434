import { useColorModeValue, VStack } from '@chakra-ui/react';
import { ReactNode, useState } from 'react';

import { AskAiButton, AskAiButtonProps } from './ask-ai-button';

export interface HoverAskAiButtonProps<T> extends AskAiButtonProps<T> {
  spacing?: string | number;
  children?: ReactNode;
}

export function HoverAskAiButton<T>({
  spacing = '1.5',
  children,
  ...props
}: HoverAskAiButtonProps<T>) {
  const askAiBgColor = useColorModeValue('white', 'gray.700');
  const [isVisible, setIsVisible] = useState(false);

  const onRecommendationRequest = () => {
    setIsVisible(true);
    return props.onRecommendationRequest();
  };
  const onRecommendationAccept = (recommendation: T) => {
    setIsVisible(false);
    return props.onRecommendationAccept(recommendation);
  };
  const onRecommendationCancel = () => {
    setIsVisible(false);
    return props.onRecommendationCancel?.();
  };

  return (
    <VStack
      position="absolute"
      top={spacing}
      right={spacing}
      justifyContent="center"
      display={isVisible ? 'flex' : 'none'}
      _groupHover={{ display: 'flex' }}
      zIndex="1"
    >
      <AskAiButton
        bgColor={askAiBgColor}
        {...props}
        onRecommendationRequest={onRecommendationRequest}
        onRecommendationAccept={onRecommendationAccept}
        onRecommendationCancel={onRecommendationCancel}
      >
        {children}
      </AskAiButton>
    </VStack>
  );
}

HoverAskAiButton.ParentProps = {
  position: 'relative',
  'data-group': true,
} as const;
