import { datadogLogs } from '@datadog/browser-logs';
import { ArrowPathIcon, BellAlertIcon } from '@heroicons/react/24/outline';
import { Vendor_Questionnaire_Statuses_Enum } from '@main/graphql/types.generated';
import { toError, useStableCallback } from '@main/shared/utils';
import { errorToast, successToast, useAlertDialog } from '@main/ui';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgRole } from '../user/slice';
import { useUpdateVendorQuestionnaireMutation } from '../vendors/vendor-questionnaires/vendor-questionnaires.generated';

export function useResendQuestionnaireAction() {
  const { t } = useTranslation();
  const { openDialog } = useAlertDialog();
  const [updateVendorQuestionnaire] = useUpdateVendorQuestionnaireMutation();

  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canUpdateVendor = userRole.permissionMap?.write_vendors;

  return useStableCallback(
    ({
      status,
      vendorQuestionnaireId,
      isVendorDisabled,
    }: {
      status: Vendor_Questionnaire_Statuses_Enum;
      vendorQuestionnaireId: string;
      isVendorDisabled: boolean;
    }) => {
      if (!canUpdateVendor || isVendorDisabled) {
        return null;
      }
      const hasSubmitted = status === Vendor_Questionnaire_Statuses_Enum.Submitted;
      const translationPath = hasSubmitted ? 'requestModifications' : 'sendReminder';

      return {
        icon: hasSubmitted ? <ArrowPathIcon /> : <BellAlertIcon />,
        label: t(`vendors.questionnaires.actions.resendOptions.${translationPath}`),
        onClick: () =>
          openDialog({
            dialogHeader: t(`vendors.questionnaires.${translationPath}Alert.header`),
            dialogContent: t(`vendors.questionnaires.${translationPath}Alert.content`),
            confirmAction: {
              children: t(`vendors.questionnaires.${translationPath}Alert.confirmLabel`),
              colorScheme: 'blue',
              onClick: async () => {
                try {
                  await updateVendorQuestionnaire({
                    vendorQuestionnaireId,
                    input: { status: Vendor_Questionnaire_Statuses_Enum.Created },
                  }).unwrap();
                  successToast(t(`vendors.questionnaires.${translationPath}Alert.toasts.success`));
                } catch (error) {
                  errorToast(t(`vendors.questionnaires.${translationPath}Alert.toasts.failed`));
                  datadogLogs.logger.error(
                    'Vendor questionnaire re-send failed',
                    { vendorQuestionnaireId },
                    toError(error),
                  );
                }
              },
            },
          }),
      };
    },
  );
}
