import {
  chakra,
  Editable,
  EditableInput as ChakraEditableInput,
  EditableInputProps as ChakraEditableInputProps,
  EditablePreview,
  EditablePreviewProps,
  EditableProps,
  forwardRef,
  Input,
  Link,
  Text,
  Tooltip,
  useColorModeValue,
  useEditableContext,
  useEditableStyles,
  useFormControl,
} from '@chakra-ui/react';
import useKeyPress from 'ahooks/es/useKeyPress';
import { HTMLInputTypeAttribute, ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { formatDate, formatDateTime } from '../../utils/locale-date';
import { PROPERTY_DEFAULT_HEIGHT } from '../drawer';
import { shortcutHint, ShortcutModifier } from '../shortcut';

export interface EditableInputProps {
  editable?: EditableProps;
  input?: ChakraEditableInputProps;
  placeholder?: string;
}

export const EditableInput = forwardRef<EditableInputProps, 'div'>((props, ref) => {
  const { t } = useTranslation('ui');
  const hoverBgColor = useColorModeValue('gray.100', 'gray.600');
  const focusBgColor = useColorModeValue('gray.25', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'rgba(255,255,255,0.16)');
  const placeholderColor = useColorModeValue('gray.400', 'gray.500');
  const valueColor = useColorModeValue('gray.600', 'gray.600');
  const inputRef = useRef<HTMLInputElement>(null);
  const inputPreviewRef = useRef<HTMLElement>(null);
  const [isValid, setIsValid] = useState(false);
  const [value, setValue] = useState(props.editable?.defaultValue ?? '');
  const { id, readOnly, disabled, required } = useFormControl({});
  const isDisabled = props.editable?.isDisabled || disabled || readOnly;

  const revalidate = () => setIsValid(!!inputRef.current?.validity.valid);

  const onSubmit = (nextValue: string) => {
    if (isValid) {
      props.editable?.onSubmit?.(nextValue);
    } else {
      setValue(props.editable?.defaultValue ?? '');
    }
  };

  const handlePreviewClick = () => {
    if (inputPreviewRef.current) {
      inputPreviewRef.current.focus();
    }
  };

  // Handle label click to focus textarea
  useEffect(() => {
    const labelElement = document.querySelector(`label[for="${id}"]`);
    if (labelElement) {
      labelElement.addEventListener('click', handlePreviewClick);
    }
    return () => {
      if (labelElement) {
        labelElement.removeEventListener('click', handlePreviewClick);
      }
    };
  }, [id]);

  useEffect(() => revalidate(), []);

  return (
    <Editable
      isDisabled={isDisabled}
      placeholder={props.placeholder ?? t('editable.placeholder')}
      {...props.editable}
      value={value}
      onChange={(value) => setValue(value)}
      ref={ref}
      onSubmit={onSubmit}
    >
      <ChakraEditableInput
        {...props.input}
        as={Input}
        id={id}
        px={2}
        py={0}
        w="full"
        h={PROPERTY_DEFAULT_HEIGHT}
        lineHeight={PROPERTY_DEFAULT_HEIGHT}
        fontSize="xs"
        isInvalid={!isValid}
        disabled={disabled}
        required={required}
        readOnly={readOnly}
        ref={inputRef}
        _focusVisible={{
          borderColor: borderColor,
          bgColor: focusBgColor,
        }}
        _focus={{
          boxShadow: 'none',
        }}
        _invalid={{
          borderColor: 'red.300',
        }}
        onChange={() => revalidate()}
        onFocus={() => revalidate()}
      />
      <EditableInputPreview
        type={props.input?.type}
        ref={inputPreviewRef}
        aria-labelledby={`${id}-label`}
        role="button"
        py={2}
        width="full"
        h="full"
        border="1px"
        borderColor="transparent"
        color={inputRef.current?.value ? valueColor : placeholderColor}
        _hover={isDisabled ? {} : { bg: hoverBgColor, borderColor: hoverBgColor }}
        cursor={isDisabled ? 'not-allowed' : 'pointer'}
      />
    </Editable>
  );
});

interface EditableInputPreviewProps extends EditablePreviewProps {
  type?: HTMLInputTypeAttribute;
}

const EditableInputPreview = forwardRef<EditableInputPreviewProps, 'span'>(
  ({ type, ...rest }: EditableInputPreviewProps, ref) => {
    const { getPreviewProps, value } = useEditableContext();
    const styles = useEditableStyles();
    const previewProps = getPreviewProps(rest, ref);
    const isDate = type === 'date' || type === 'datetime-local';
    const isUrl = type === 'url';
    const isEmail = type === 'email';
    const isCustomPreview = isDate || isUrl || isEmail;

    if (isCustomPreview) {
      return (
        <chakra.span
          {...previewProps}
          {...rest}
          ref={ref}
          __css={{
            ...styles['preview'],
          }}
          display="flex"
          alignContent="center"
          px={2}
        >
          {isDate && (
            <EditableInputPreviewDate
              type={type}
              value={value}
              placeholder={previewProps.children}
            />
          )}

          {isUrl && <EditableInputPreviewUrl value={value} placeholder={previewProps.children} />}

          {isEmail && (
            <EditableInputPreviewEmail value={value} placeholder={previewProps.children} />
          )}
        </chakra.span>
      );
    }

    return (
      <EditablePreview
        {...rest}
        ref={ref}
        display="flex"
        alignItems="center"
        fontSize="xs"
        px={2}
      />
    );
  },
);

interface EditableInputPreviewDateProps {
  type?: HTMLInputTypeAttribute;
  value?: string;
  placeholder?: ReactNode;
}

const EditableInputPreviewDate = ({ type, value, placeholder }: EditableInputPreviewDateProps) => {
  if (!value) {
    return (
      <Text fontSize="xs" lineHeight="20px">
        {placeholder}
      </Text>
    );
  }

  if (type === 'date') {
    return (
      <Text fontSize="xs" lineHeight="20px">
        {formatDate(value)}
      </Text>
    );
  }

  return (
    <Text fontSize="xs" lineHeight="20px">
      {formatDateTime(value)}
    </Text>
  );
};

interface EditableInputPreviewUrlProps {
  value?: string;
  placeholder?: ReactNode;
}

const EditableInputPreviewUrl = ({ value, placeholder }: EditableInputPreviewUrlProps) => {
  const isCtrlKeyPressedRef = useRef(false);
  const { t } = useTranslation('ui');

  useKeyPress(
    ['ctrl', 'meta'],
    (e) => {
      e.type === 'keydown' && (isCtrlKeyPressedRef.current = true);
      e.type === 'keyup' && (isCtrlKeyPressedRef.current = false);
    },
    { events: ['keyup', 'keydown'] },
  );

  if (!value) {
    return (
      <Text fontSize="xs" lineHeight="20px">
        {placeholder}
      </Text>
    );
  }

  return (
    <Tooltip
      openDelay={500}
      label={t('editable.openLinkTooltip', {
        key: shortcutHint(ShortcutModifier.Ctrl, 'Click'),
      })}
    >
      <Link
        fontSize="xs"
        color="blue.500"
        href={value}
        isExternal
        //Editable Input preview makes it editable onFocus so we can't use OnClick to stop editing(OnClick + stop propagation won't work) as it's re-rendered on focus
        onFocus={(e) => {
          if (isCtrlKeyPressedRef.current) {
            e.preventDefault();
            // we do this so soon after ctrl/cmd + click the element is not focused, so the subsequent click will not open the link in new tab
            e.currentTarget.blur();
          }
        }}
      >
        {value}
      </Link>
    </Tooltip>
  );
};

interface EditableInputPreviewEmailProps {
  value?: string;
  placeholder?: ReactNode;
}

const EditableInputPreviewEmail = ({ value, placeholder }: EditableInputPreviewEmailProps) => {
  if (!value) {
    return (
      <Text fontSize="xs" lineHeight="20px">
        {placeholder}
      </Text>
    );
  }

  return (
    <Link fontSize="xs" color="blue.500">
      {value}
    </Link>
  );
};
