import { Integration_Names_Enum } from '@main/graphql/types.generated';
import { IntegrationConfigSchemas } from '@main/integrations/shared';
import { z } from 'zod';

declare module '@main/integrations/shared' {
  interface IntegrationHandlerConfig {
    [Integration_Names_Enum.Pivotaltracker]: HandlerConfigPivotaltracker;
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type, @typescript-eslint/no-empty-interface
  interface HandlerConfigPivotaltracker {}
}

export const PivotaltrackerConfigSchemas: IntegrationConfigSchemas<Integration_Names_Enum.Pivotaltracker> =
  {
    name: Integration_Names_Enum.Pivotaltracker,

    orgConfig: z.object({
      apiToken: z.string().min(1).describe('API Token'),
    }),

    evidenceConfig: z.never(),
  };
