/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GetDuplicateFileDetailsQueryVariables = Types.Exact<{
  fileId: Types.Scalars['uuid']['input'];
}>;

export type GetDuplicateFileDetailsQuery = {
  __typename?: 'query_root';
  file?: { __typename?: 'files'; name?: string };
};

export const GetDuplicateFileDetailsDocument = `
    query GetDuplicateFileDetails($fileId: uuid!) {
  file(id: $fileId) {
    name
  }
}
    ` as string &
  TypedDocumentNode<GetDuplicateFileDetailsQuery, GetDuplicateFileDetailsQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetDuplicateFileDetails: build.query<
      GetDuplicateFileDetailsQuery,
      GetDuplicateFileDetailsQueryVariables
    >({
      query: (variables) => ({ document: GetDuplicateFileDetailsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetDuplicateFileDetailsQuery, useLazyGetDuplicateFileDetailsQuery } =
  injectedRtkApi;
