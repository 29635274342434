import { patchNhostStorageHeaders } from '@main/shared/storage';
import { FileResponse, NhostClient, StorageUploadResponse } from '@nhost/nhost-js';

export const nhost = new NhostClient({
  authUrl: import.meta.env['VITE_NHOST_AUTH_URL'] || '',
  functionsUrl: ' ',
  graphqlUrl: import.meta.env['VITE_NHOST_GRAPHQL_URL'] || '',
  storageUrl: import.meta.env['VITE_NHOST_STORAGE_URL'] || '',
  /**
   * access token is valid for 15 mins. and default time to rotate token is 30 secs before expiry and the interval is every min. for verification
   * changing it to 12 mins and it may solve the random expired token error because of inactive browser tab and browser throttling timers during this time
   *  */
  refreshIntervalTime: 60 * 12,
});

export function getUploadedFileMetadata(
  input: NonNullable<StorageUploadResponse['fileMetadata']>,
): FileResponse {
  // To get here, the error in upload response should have been checked already,
  // therefore we should have at least one entry in `processedFiles` array.
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return 'processedFiles' in input ? input.processedFiles[0]! : input;
}

patchNhostStorageHeaders(nhost, 'user');
