import { HashtagIcon } from '@heroicons/react/24/outline';
import { DrawerProperty, EditableInput } from '@main/ui';
import { useTranslation } from 'react-i18next';

const FLOATING_POINT_REGEX = /^[Ee0-9+\-.]$/;

function isFloatingPointNumericCharacter(character: string) {
  return FLOATING_POINT_REGEX.test(character);
}

function isValidNumericKeyboardEvent(
  event: React.KeyboardEvent,
  isValid: (key: string) => boolean,
) {
  if (event.key == null) return true;
  const isModifierKey = event.ctrlKey || event.altKey || event.metaKey;
  const isSingleCharacterKey = event.key.length === 1;
  if (!isSingleCharacterKey || isModifierKey) return true;
  return isValid(event.key);
}

export const NumberFieldInput = ({
  name,
  value,
  onChange,
  isReadOnly,
}: {
  name: string;
  value?: number;
  onChange: (value: number | undefined) => void;
  isReadOnly?: boolean;
}) => {
  const { t } = useTranslation('ui');

  return (
    <DrawerProperty isReadOnly={isReadOnly}>
      <DrawerProperty.Label icon={HashtagIcon}>{name}</DrawerProperty.Label>
      <DrawerProperty.Content>
        <EditableInput
          input={{
            pattern: '[0-9]*(.[0-9]+)?',
            onKeyDown: (event) => {
              if (!isValidNumericKeyboardEvent(event, isFloatingPointNumericCharacter)) {
                event.preventDefault();
              }
            },
          }}
          placeholder={
            isReadOnly
              ? t('customFields.placeholder.hasNoPermission')
              : t('customFields.placeholder.numberField')
          }
          editable={{
            defaultValue: value?.toString(),
            onSubmit: (newValue) => {
              if (newValue === '') {
                onChange(undefined);
                return;
              }
              const newValueNumber = Number(newValue);
              if (newValueNumber !== value && !Number.isNaN(newValueNumber)) {
                onChange(newValueNumber);
              }
            },
          }}
        />
      </DrawerProperty.Content>
    </DrawerProperty>
  );
};
