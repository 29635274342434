import { datadogLogs } from '@datadog/browser-logs';
import { useDeleteVendorByIdMutation } from '@main/graphql/mutations/DeleteVendorById.generated';
import { toError } from '@main/shared/utils';
import { errorToast, successToast, useAlertDialog } from '@main/ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgId, getCurrentUserSelectedOrgRole } from '../user/slice';
import { useGetVendorsQuery } from './get-vendor.generated';

export function useDeleteVendor() {
  const { t } = useTranslation();
  const { openDialog } = useAlertDialog();

  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canDeleteVendor = !!userRole.permissionMap?.write_vendors;

  const orgId = useAppSelector(getCurrentUserSelectedOrgId);
  const { refetch: refetchVendors } = useGetVendorsQuery({ organization_id: orgId });
  const [deleteVendorById] = useDeleteVendorByIdMutation();

  const deleteVendor = useCallback(
    (vendorId: string, onDelete?: () => void) => {
      openDialog({
        dialogHeader: t('vendors.alert.delete.header'),
        dialogContent: t('vendors.alert.delete.content'),
        confirmAction: {
          children: t('vendors.alert.delete.confirm'),
          onClick: async () => {
            try {
              await deleteVendorById({ vendorId }).unwrap();
              await refetchVendors().unwrap();
              successToast(t('successMessages.deleteSucceeded', { entity: t('entities.vendor') }));
              onDelete?.();
            } catch (error) {
              errorToast(t('errorMessages.deleteFailed', { entity: t('entities.vendor') }));
              datadogLogs.logger.error('Vendor delete failed', {}, toError(error));
            }
          },
        },
      });
    },
    [t, openDialog, deleteVendorById, refetchVendors],
  );

  return canDeleteVendor ? deleteVendor : null;
}
