import { Avatar, Flex, Stack, Tag, Text, useColorModeValue } from '@chakra-ui/react';
import { AtSymbolIcon, CalendarDaysIcon, LinkIcon, UserIcon } from '@heroicons/react/24/outline';
import { formatDate, getQuestionnaireType } from '@main/shared/utils';
import { DrawerProperty } from '@main/ui';
import { ReferenceCard } from '@main/ui/cards';
import { BarsIcon, TagIcon } from '@main/ui/icons';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../hooks/redux-toolkit-hooks';
import { VENDOR_ASSESSMENT_STATUS_COLOR } from '../../shared/status-color';
import { getFormattedVendorQuestionnaire } from '../slice';

export const VendorQuestionnaireDetailsTab = ({ vqId }: { vqId: string }) => {
  const { t } = useTranslation();
  const vq = useAppSelector((state) => getFormattedVendorQuestionnaire(state, vqId));
  const type = getQuestionnaireType(vq.questionnaire);

  const linkTextColor = useColorModeValue('blue.600', 'blue.300');
  const textColor = useColorModeValue('gray.600', 'gray.300');

  if (!vq) {
    return null;
  }

  return (
    <Stack spacing={[6, 3]}>
      {vq.questionnaire?.description ? (
        <DrawerProperty>
          <DrawerProperty.Label icon={BarsIcon}>
            {t('vendors.questionnaires.tabs.details.description')}
          </DrawerProperty.Label>
          <DrawerProperty.Content px={2} display="flex" alignItems="center">
            <Text fontSize="xs" color={textColor}>
              {vq.questionnaire.description}
            </Text>
          </DrawerProperty.Content>
        </DrawerProperty>
      ) : null}

      <DrawerProperty>
        <DrawerProperty.Label icon={TagIcon}>
          {t('vendors.questionnaires.tabs.details.type')}
        </DrawerProperty.Label>
        <DrawerProperty.Content px={2} display="flex" alignItems="center">
          <Tag colorScheme="purple" w="fit-content">
            {t(`questionnaires.type.${type}`)}
          </Tag>
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty>
        <DrawerProperty.Label icon={AtSymbolIcon}>
          {t('vendors.questionnaires.tabs.details.sentTo')}
        </DrawerProperty.Label>
        <DrawerProperty.Content px={2} display="flex" alignItems="center">
          <Text fontSize="xs" color={linkTextColor} textDecoration="underline">
            {vq?.sent_to_email}
          </Text>
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty>
        <DrawerProperty.Label icon={UserIcon}>
          {t('vendors.questionnaires.tabs.details.sentBy')}
        </DrawerProperty.Label>
        <DrawerProperty.Content px={2}>
          <Flex gap={2} alignItems="center" h="full">
            <Avatar name={vq?.sent_by_user?.displayName} size="xs" />
            <Text fontSize="xs" color="gray.500" fontWeight="medium">
              {vq?.sent_by_user?.displayName}
            </Text>
          </Flex>
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty>
        <DrawerProperty.Label icon={CalendarDaysIcon}>
          {t('vendors.questionnaires.tabs.details.dueDate')}
        </DrawerProperty.Label>
        <DrawerProperty.Content px={2} display="flex" alignItems="center">
          <Text fontSize="xs" color={textColor}>
            {formatDate(vq?.due_date)}
          </Text>
        </DrawerProperty.Content>
      </DrawerProperty>

      {vq?.submitted_at ? (
        <DrawerProperty>
          <DrawerProperty.Label icon={CalendarDaysIcon}>
            {t('vendors.questionnaires.tabs.details.submittedDate')}
          </DrawerProperty.Label>
          <DrawerProperty.Content px={2} display="flex" alignItems="center">
            <Text fontSize="xs" color={textColor}>
              {formatDate(vq.submitted_at)}
            </Text>
          </DrawerProperty.Content>
        </DrawerProperty>
      ) : null}

      <DrawerProperty>
        <DrawerProperty.Label icon={LinkIcon}>
          {t('vendors.questionnaires.references')}
        </DrawerProperty.Label>
        <DrawerProperty.Content px={2} display="flex" flexDirection="column" gap={2}>
          {vq.vendor && (
            <ReferenceCard
              name={vq.vendor.name}
              owner={vq.vendor.owner?.displayName}
              navigate={{
                drawerEntity: 'vendor',
                drawerEntityId: vq.vendor.id,
              }}
              tags={[
                {
                  label: t('entities.vendor'),
                  colorSchema: 'purple',
                },
                {
                  type: 'status',
                  label: t(`vendors.enum.assessmentStatus.${vq.vendor.assessment_status}`),
                  colorSchema: VENDOR_ASSESSMENT_STATUS_COLOR[vq.vendor.assessment_status],
                },
              ]}
            />
          )}
          {vq.questionnaire && (
            <ReferenceCard
              name={vq.questionnaire.name}
              owner={vq.questionnaire.owner?.displayName}
              navigate={{
                drawerEntity: 'questionnaire',
                drawerEntityId: vq.questionnaire.id,
              }}
              tags={[
                {
                  label: t('entities.questionnaire'),
                  colorSchema: 'purple',
                },
              ]}
            />
          )}
        </DrawerProperty.Content>
      </DrawerProperty>
    </Stack>
  );
};
