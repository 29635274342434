/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { CommentsFragmentFragmentDoc } from '../../../../../libs/graphql/src/fragments/comments.fragment.generated';
import { ReviewDetailsFragmentDoc } from '../reviews/review.fragment.generated';
import { FieldValueFragmentDoc } from '../../../../../libs/graphql/src/fragments/FieldValueFragments.generated';
import { api } from '@main/graphql/shared-api';
export type GetRiskQueryVariables = Types.Exact<{
  risk_id: Types.Scalars['uuid']['input'];
}>;

export type GetRiskQuery = {
  __typename?: 'query_root';
  risks_by_pk?: {
    __typename?: 'risks';
    id: string;
    name?: string;
    description?: string;
    internal_id?: string;
    treatment_details?: string;
    treatment_plan: Types.Treatment_Plan_Enum;
    assessment_status: ClientTypes.RiskAssessmentStatus;
    organization_id: string;
    review_frequency: Types.Frequencies_Enum;
    closed_at?: string;
    last_review_date?: string;
    assignee?: { __typename?: 'users'; displayName: string; avatarUrl: string; id: string };
    risks_comments: Array<{
      __typename?: 'comments';
      id: string;
      text: string;
      created_at: string;
      updated_at: string;
      user_id: string;
      comments_comment_uploads: Array<{
        __typename?: 'comment_uploads';
        id: string;
        comment_uploads_file: {
          __typename?: 'files';
          id: string;
          name?: string;
          size?: number;
          createdAt: string;
        };
      }>;
    }>;
    documents: Array<{
      __typename?: 'risk_documents';
      file?: { __typename?: 'files'; id: string; name?: string; size?: number };
    }>;
    reviews: Array<{
      __typename?: 'reviews';
      id: string;
      name: string;
      notes?: string;
      review_date: string;
      reviews_user?: { __typename?: 'users'; id: string; displayName: string };
      reviews_review_files: Array<{
        __typename?: 'review_files';
        id: string;
        review_files_file: { __typename?: 'files'; id: string; name?: string; size?: number };
      }>;
    }>;
    risk_controls: Array<{
      __typename?: 'risk_controls';
      id: string;
      is_control_primary: boolean;
      control: {
        __typename?: 'controls';
        id: string;
        internal_id?: string;
        status: ClientTypes.ControlStatus;
        name?: string;
      };
    }>;
    vendor_risks: Array<{
      __typename?: 'vendor_risks';
      id: string;
      vendor: {
        __typename?: 'vendors';
        id: string;
        name?: string;
        assessment_status: ClientTypes.VendorAssessmentStatus;
        owner?: { __typename?: 'users'; displayName: string };
      };
    }>;
    tags: Array<{
      __typename?: 'risk_tags';
      id: string;
      tag: { __typename?: 'organization_risk_tags'; id: string; name: string };
    }>;
    categories: Array<{
      __typename?: 'risk_categories';
      id: string;
      category: { __typename?: 'organization_risk_categories'; id: string; name: string };
    }>;
    inherent_impact?: { __typename?: 'risk_impacts'; name: string };
    inherent_likelihood?: { __typename?: 'risk_likelihoods'; name: string };
    residual_impact?: { __typename?: 'risk_impacts'; name: string };
    residual_likelihood?: { __typename?: 'risk_likelihoods'; name: string };
    field_values: Array<{
      __typename?: 'field_values';
      field_config_id: string;
      entity_name: Types.Field_Entities_Enum;
      field_type: Types.Field_Types_Enum;
      text_field_value?: {
        __typename?: 'text_field_values';
        field_value_id: string;
        value: string;
      };
      url_field_value?: { __typename?: 'url_field_values'; field_value_id: string; value: string };
      date_field_value?: {
        __typename?: 'date_field_values';
        field_value_id: string;
        value: string;
      };
      number_field_value?: {
        __typename?: 'number_field_values';
        field_value_id: string;
        value: number;
      };
      email_field_value?: {
        __typename?: 'email_field_values';
        field_value_id: string;
        value: string;
      };
      select_field_value?: {
        __typename?: 'select_field_values';
        field_value_id: string;
        select_field_option_id: string;
      };
    }>;
  };
};

export type GetRisksQueryVariables = Types.Exact<{
  organization_id: Types.Scalars['uuid']['input'];
}>;

export type GetRisksQuery = {
  __typename?: 'query_root';
  risks: Array<{
    __typename?: 'risks';
    id: string;
    name?: string;
    internal_id?: string;
    description?: string;
    assessment_status: ClientTypes.RiskAssessmentStatus;
    organization_id: string;
    review_frequency: Types.Frequencies_Enum;
    closed_at?: string;
    treatment_plan: Types.Treatment_Plan_Enum;
    treatment_details?: string;
    last_review_date?: string;
    assignee?: { __typename?: 'users'; id: string; avatarUrl: string; displayName: string };
    risk_controls: Array<{
      __typename?: 'risk_controls';
      is_control_primary: boolean;
      control: { __typename?: 'controls'; name?: string; status: ClientTypes.ControlStatus };
    }>;
    tags: Array<{
      __typename?: 'risk_tags';
      id: string;
      tag: { __typename?: 'organization_risk_tags'; id: string; name: string };
    }>;
    categories: Array<{
      __typename?: 'risk_categories';
      id: string;
      category: { __typename?: 'organization_risk_categories'; id: string; name: string };
    }>;
    vendor_risks: Array<{
      __typename?: 'vendor_risks';
      id: string;
      vendor: { __typename?: 'vendors'; id: string; name?: string };
    }>;
    inherent_impact?: { __typename?: 'risk_impacts'; name: string };
    inherent_likelihood?: { __typename?: 'risk_likelihoods'; name: string };
    residual_impact?: { __typename?: 'risk_impacts'; name: string };
    residual_likelihood?: { __typename?: 'risk_likelihoods'; name: string };
    field_values: Array<{
      __typename?: 'field_values';
      field_config_id: string;
      entity_name: Types.Field_Entities_Enum;
      field_type: Types.Field_Types_Enum;
      text_field_value?: {
        __typename?: 'text_field_values';
        field_value_id: string;
        value: string;
      };
      url_field_value?: { __typename?: 'url_field_values'; field_value_id: string; value: string };
      date_field_value?: {
        __typename?: 'date_field_values';
        field_value_id: string;
        value: string;
      };
      number_field_value?: {
        __typename?: 'number_field_values';
        field_value_id: string;
        value: number;
      };
      email_field_value?: {
        __typename?: 'email_field_values';
        field_value_id: string;
        value: string;
      };
      select_field_value?: {
        __typename?: 'select_field_values';
        field_value_id: string;
        select_field_option_id: string;
      };
    }>;
  }>;
};

export const GetRiskDocument = `
    query GetRisk($risk_id: uuid!) {
  risks_by_pk(id: $risk_id) {
    id
    name
    description
    internal_id
    assignee {
      displayName
      avatarUrl
      id
    }
    treatment_details
    treatment_plan
    assessment_status
    organization_id
    review_frequency
    closed_at
    risks_comments(order_by: {created_at: asc}) {
      ...CommentsFragment
    }
    documents {
      file {
        id
        name
        size
      }
    }
    reviews(order_by: {review_date: desc}) {
      ...ReviewDetails
    }
    last_review_date
    risk_controls {
      id
      is_control_primary
      control {
        id
        internal_id
        status
        name
      }
    }
    vendor_risks {
      id
      vendor {
        id
        name
        assessment_status
        owner {
          displayName
        }
      }
    }
    tags {
      id
      tag {
        id
        name
      }
    }
    categories {
      id
      category {
        id
        name
      }
    }
    inherent_impact {
      name
    }
    inherent_likelihood {
      name
    }
    residual_impact {
      name
    }
    residual_likelihood {
      name
    }
    field_values {
      ...FieldValue
    }
  }
}
    ${CommentsFragmentFragmentDoc}
${ReviewDetailsFragmentDoc}
${FieldValueFragmentDoc}` as string & TypedDocumentNode<GetRiskQuery, GetRiskQueryVariables>;
export const GetRisksDocument = `
    query GetRisks($organization_id: uuid!) {
  risks(
    order_by: {internal_id: asc}
    where: {organization_id: {_eq: $organization_id}}
  ) {
    id
    name
    internal_id
    description
    assignee {
      id
      avatarUrl
      displayName
    }
    assessment_status
    organization_id
    review_frequency
    closed_at
    risk_controls {
      is_control_primary
      control {
        name
        status
      }
    }
    tags {
      id
      tag {
        id
        name
      }
    }
    categories {
      id
      category {
        id
        name
      }
    }
    vendor_risks {
      id
      vendor {
        id
        name
      }
    }
    inherent_impact {
      name
    }
    inherent_likelihood {
      name
    }
    residual_impact {
      name
    }
    residual_likelihood {
      name
    }
    treatment_plan
    treatment_details
    field_values {
      ...FieldValue
    }
    last_review_date
  }
}
    ${FieldValueFragmentDoc}` as string & TypedDocumentNode<GetRisksQuery, GetRisksQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetRisk: build.query<GetRiskQuery, GetRiskQueryVariables>({
      query: (variables) => ({ document: GetRiskDocument, variables }),
    }),
    GetRisks: build.query<GetRisksQuery, GetRisksQueryVariables>({
      query: (variables) => ({ document: GetRisksDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetRiskQuery, useLazyGetRiskQuery, useGetRisksQuery, useLazyGetRisksQuery } =
  injectedRtkApi;
