import { useFormControlContext } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import {
  useAddRiskCategoryMutation,
  useCreateRiskCategoryMutation,
  useLazyGetRiskCategoriesQuery,
  useRemoveRiskCategoryMutation,
} from '@main/graphql/features/RiskCategories.generated';
import { toError } from '@main/shared/utils';
import { EditableTag, errorToast } from '@main/ui';
import { ActionMeta, MultiValue } from 'chakra-react-select';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgId } from '../user/slice';
import { api as getRiskApi, useGetRisksQuery } from './get-risk.generated';
import { getRiskCategories, Option } from './slice';

export const Categories = ({ riskId }: { riskId: string }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isReadOnly } = useFormControlContext();
  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);
  const { refetch: refetchRisks } = useGetRisksQuery({ organization_id: organizationId });

  const categories = useAppSelector((state) => getRiskCategories(state, riskId));
  const [searchCategories] = useLazyGetRiskCategoriesQuery();
  const [createCategory] = useCreateRiskCategoryMutation();
  const [addCategory] = useAddRiskCategoryMutation();
  const [removeCategory] = useRemoveRiskCategoryMutation();

  const searchCustomTagsHandler = async (inputValue: string) => {
    const { organization_risk_categories } = await searchCategories({
      organizationId,
      ...(inputValue?.trim() ? { nameFilter: { _ilike: `%${inputValue}%` } } : {}),
    }).unwrap();

    return (
      organization_risk_categories.map((category) => ({
        value: category.id,
        label: category.name,
        colorScheme: 'purple',
        id: '',
      })) || []
    );
  };

  const handler = async (
    resultPromise: ReturnType<
      ReturnType<
        | typeof useCreateRiskCategoryMutation
        | typeof useAddRiskCategoryMutation
        | typeof useRemoveRiskCategoryMutation
      >[0]
    >,
  ) => {
    try {
      await resultPromise.unwrap();
      refetchRisks();
    } catch (error) {
      errorToast(t('errorMessages.tagFailed', { entity: t('entities.risk') }));
      datadogLogs.logger.error('Unable to perform operation on categories.', {}, toError(error));
      return;
    }
  };

  const handleChange = async (items: MultiValue<Option>, actionMeta: ActionMeta<Option>) => {
    switch (actionMeta.action) {
      case 'create-option': {
        dispatch(
          getRiskApi.util.updateQueryData('GetRisk', { risk_id: riskId }, (draft) => {
            if (draft.risks_by_pk) {
              draft.risks_by_pk.categories.push({
                id: 'new',
                category: { id: 'new', name: actionMeta.option.label },
              });
            }
          }),
        );
        await handler(
          createCategory({
            object: {
              risk_id: riskId,
              category: {
                data: {
                  name: actionMeta.option.label,
                  organization_id: organizationId,
                },
              },
            },
          }),
        );
        break;
      }
      case 'select-option': {
        dispatch(
          getRiskApi.util.updateQueryData('GetRisk', { risk_id: riskId }, (draft) => {
            if (draft.risks_by_pk) {
              draft.risks_by_pk.categories.push({
                id: 'new',
                category: {
                  id: actionMeta.option?.value as string,
                  name: actionMeta.option?.label as string,
                },
              });
            }
          }),
        );
        await handler(addCategory({ categoryId: actionMeta.option?.value as string, riskId }));
        break;
      }
      case 'remove-value':
      case 'pop-value': {
        dispatch(
          getRiskApi.util.updateQueryData('GetRisk', { risk_id: riskId }, (draft) => {
            if (draft.risks_by_pk) {
              const remainingTags = draft.risks_by_pk.categories.filter(
                ({ category }) => category.id !== actionMeta.removedValue.value,
              );
              draft.risks_by_pk.categories = remainingTags;
            }
          }),
        );
        await handler(removeCategory({ id: actionMeta.removedValue.id || '' }));
        break;
      }
    }
    await dispatch(
      getRiskApi.endpoints.GetRisk.initiate(
        { risk_id: riskId },
        { forceRefetch: true, subscribe: false },
      ),
    ).unwrap();
  };

  return (
    <EditableTag
      isMulti={true}
      debounceMs={500}
      value={categories}
      loadOptions={searchCustomTagsHandler}
      onChange={handleChange}
      getNewOptionData={(label) => ({ label, value: 'new', id: 'new', colorScheme: 'purple' })}
      variant="inline"
      placeholder={
        isReadOnly
          ? t('risks.hasNoPermission', {
              property: t('risks.details.category').toLowerCase(),
            })
          : t('risks.details.placeholder.category')
      }
    />
  );
};
