import { datadogLogs } from '@datadog/browser-logs';
import { ArrowPathRoundedSquareIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { RiskAssessmentStatus } from '@main/graphql/client-scalars';
import { isNonNullable, toError, useStableCallback } from '@main/shared/utils';
import { errorToast, successToast, useAlertDialog, useDrawer } from '@main/ui';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgRole } from '../user/slice';
import { useDeleteRisk } from './delete-risk';
import { getMappedRisk } from './slice';
import { useToggleCloseOutRiskMutation } from './update-risk.generated';
import { useRefetchRiskQueries } from './use-update-risk-handler';

export const useRiskDrawerActions = (riskId: string) => {
  const deleteRisk = useDeleteRiskAction(riskId);
  const risk = useAppSelector((state) => getMappedRisk(state, riskId));

  const toggleCloseRisk = useLazyToggleRiskCloseAction()({
    riskId,
    status: risk?.assessment_status,
  });

  return {
    menuActions: [toggleCloseRisk, deleteRisk].filter(isNonNullable),
  };
};

type ToggleCloseAction = { riskId: string; status: RiskAssessmentStatus | undefined };

export function useLazyToggleRiskCloseAction() {
  const { t } = useTranslation();
  const { openDialog } = useAlertDialog();

  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canUpdateRisk = userRole.permissionMap?.write_risks;

  const [updateClosedState] = useToggleCloseOutRiskMutation();
  const refetchRisks = useRefetchRiskQueries();

  const handler = useStableCallback(async ({ riskId, status: prevState }: ToggleCloseAction) => {
    try {
      await updateClosedState({ riskId }).unwrap();

      successToast(
        prevState !== RiskAssessmentStatus.CLOSED
          ? t('risks.alert.close.successToast')
          : t('risks.alert.reopen.successToast'),
      );
      await refetchRisks();
    } catch (error) {
      errorToast(t('errorMessages.updateFailed', { entity: t('entities.risk') }));
      datadogLogs.logger.error('Updating risk closed state failed', { riskId }, toError(error));
    }
  });

  return useCallback(
    ({ riskId, status }: ToggleCloseAction) => {
      if (!canUpdateRisk) {
        return;
      }

      if (status === RiskAssessmentStatus.CLOSED) {
        return {
          icon: <ArrowPathRoundedSquareIcon />,
          label: t('risks.alert.reopen.confirm'),
          onClick: () => {
            openDialog({
              dialogHeader: t('risks.alert.reopen.header'),
              dialogContent: t('risks.alert.reopen.content'),
              confirmAction: {
                colorScheme: 'blue',
                children: t('risks.alert.reopen.confirm'),
                onClick: async () => {
                  await handler({ riskId, status });
                },
              },
            });
          },
        };
      }

      return {
        icon: <XMarkIcon />,
        label: t('risks.alert.close.header'),
        onClick: () => {
          openDialog({
            dialogHeader: t('risks.alert.close.header'),
            dialogContent: t('risks.alert.close.content'),
            confirmAction: {
              colorScheme: 'blue',
              children: t('risks.alert.close.confirm'),
              onClick: async () => {
                await handler({ riskId, status });
              },
            },
          });
        },
      };
    },
    [canUpdateRisk, handler, openDialog, t],
  );
}

function useDeleteRiskAction(riskId: string) {
  const { t } = useTranslation();
  const drawer = useDrawer();
  const deleteRisk = useDeleteRisk();

  return useMemo(() => {
    if (!deleteRisk) {
      return null;
    }

    return {
      icon: <TrashIcon />,
      label: t('buttons.delete'),
      onClick: () => {
        deleteRisk(riskId, () => drawer.close());
      },
    };
  }, [deleteRisk, drawer, riskId, t]);
}
