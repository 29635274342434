import { Integration_Names_Enum } from '@main/graphql/types.generated';
import { IntegrationConfigSchemas } from '@main/integrations/shared';
import { z } from 'zod';

declare module '@main/integrations/shared' {
  interface IntegrationHandlerConfig {
    [Integration_Names_Enum.Freshdesk]: HandlerConfigFreshdesk;
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type, @typescript-eslint/no-empty-interface
  interface HandlerConfigFreshdesk {}
}

export const FreshdeskConfigSchemas: IntegrationConfigSchemas<Integration_Names_Enum.Freshdesk> = {
  name: Integration_Names_Enum.Freshdesk,

  orgConfig: z.object({
    baseUrl: z.string().min(1).describe('API Base URL'),
    apiKey: z.string().min(1).describe('API Key'),
  }),
  evidenceConfig: z.never(),
};
