import { Integration_Names_Enum } from '@main/graphql/types.generated';
import { IntegrationConfigSchemas } from '@main/integrations/shared';
import { z } from 'zod';

declare module '@main/integrations/shared' {
  interface IntegrationHandlerConfig {
    [Integration_Names_Enum.Cloudflare]: HandlerConfigCloudflare;
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type, @typescript-eslint/no-empty-interface
  interface HandlerConfigCloudflare {}
}

export const CloudflareConfigSchemas: IntegrationConfigSchemas<Integration_Names_Enum.Cloudflare> =
  {
    name: Integration_Names_Enum.Cloudflare,

    orgConfig: z.object({
      apiToken: z.string().min(40).describe('API Token'),
    }),

    evidenceConfig: z.never(),
  };
