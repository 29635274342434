import { DrawerProperty, EditableTag, selectValueToArray } from '@main/ui';
import { DoubleTagsIcon, TagIcon } from '@main/ui/icons';
import { useTranslation } from 'react-i18next';

export const SelectFieldInput = ({
  name,
  options,
  values,
  isMulti,
  isCreatable,
  onChange,
  isReadOnly,
}: {
  name: string;
  options: string[];
  values?: string[];
  isMulti?: boolean;
  isCreatable?: boolean;
  onChange: (values: string[]) => void;
  isReadOnly?: boolean;
}) => {
  const { t } = useTranslation('ui');
  const tagValues = values?.map(toTagOption);
  const tagOptions = options.map(toTagOption);

  const getPlaceholder = () => {
    if (isReadOnly) {
      return t('customFields.placeholder.hasNoPermission');
    }

    if (isCreatable) {
      return t('customFields.placeholder.creatableSelectField');
    }

    return t('customFields.placeholder.selectField');
  };

  return (
    <DrawerProperty isReadOnly={isReadOnly}>
      <DrawerProperty.Label icon={isMulti ? DoubleTagsIcon : TagIcon}>{name}</DrawerProperty.Label>
      <DrawerProperty.Content>
        <EditableTag
          isMulti={isMulti}
          value={isMulti ? tagValues : tagValues?.[0]}
          options={tagOptions}
          placeholder={getPlaceholder()}
          onChange={(nextTagValues) => {
            const nextValues = selectValueToArray(nextTagValues).map(({ value }) => value);
            onChange(nextValues);
          }}
          getNewOptionData={isCreatable ? toTagOption : undefined}
        />
      </DrawerProperty.Content>
    </DrawerProperty>
  );
};

function toTagOption(value: string) {
  return {
    value,
    colorScheme: 'purple',
  };
}
