import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Icon,
  IconButton,
  Link,
  Stack,
  Tag,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { PlusIcon } from '@heroicons/react/24/outline';
import { SparklesIcon } from '@heroicons/react/24/solid';
import { ControlStatus, EvidenceStatus } from '@main/graphql/client-scalars';
import { toError } from '@main/shared/utils';
import { errorToast, OverflowContainer, StatusTag } from '@main/ui';
import { Link as RouterLink } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../hooks/redux-toolkit-hooks';
import { useLinkEvidenceToControlMutation } from '../../link-entity/link-entity.generated';
import { EVIDENCE_STATUS_COLOR } from '../../shared/status-color';
import { getCurrentUserSelectedOrg, getCurrentUserSelectedOrgRole } from '../../user/slice';
import { getMappedControl } from '../slice';
import {
  GetAiSuggestionsForControlEvidencesQuery,
  useGetAiSuggestionsForControlEvidencesQuery,
} from './get-evidence-suggestions.generated';

export const AiSuggestionBlock = ({ controlId }: { controlId: string }) => {
  const { t } = useTranslation();
  const textColor = useColorModeValue('gray.600', 'gray.300');
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canLinkEvidence = userRole.permissionMap?.link_controls_evidence;
  const canReadEvidence = userRole.permissionMap.read_evidence;
  const control = useAppSelector((state) => getMappedControl(state, controlId));
  const { is_day_zero_ai_features_enabled } = useAppSelector(getCurrentUserSelectedOrg);

  const { evidences, refetch } = useGetAiSuggestionsForControlEvidencesQuery(
    {
      input: {
        controlId,
        limit: 3,
        threshold: 0.44,
      },
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !(is_day_zero_ai_features_enabled && canReadEvidence && canLinkEvidence),
      selectFromResult: ({ data }) => {
        return { evidences: data?.ai_evidence_suggestions_for_control?.evidences ?? [] };
      },
    },
  );

  const refetchSuggestions = async () => {
    await refetch().unwrap();
  };

  if (evidences.length === 0) {
    return null;
  }

  return (
    <Accordion allowToggle defaultIndex={control?.status === ControlStatus.PENDING ? 0 : undefined}>
      <AccordionItem borderWidth={1} borderRadius={6}>
        <AccordionButton justifyContent="space-between" padding={4}>
          <Flex gap={2}>
            <Icon as={SparklesIcon} color="orange.400" />
            <Box>
              <Text fontWeight={700} color="orange.400" fontSize="sm">
                {t('evidences.aiSuggestion.heading')}
              </Text>
            </Box>
          </Flex>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pt={0}>
          <Text fontSize="sm" color={textColor} pb={4} pl={6}>
            {t('evidences.aiSuggestion.subheading')}
          </Text>
          <Stack spacing={4}>
            {evidences.map((evidence) => (
              <AiSuggestionItem
                key={evidence.id}
                controlId={controlId}
                evidence={evidence}
                refetchSuggestions={refetchSuggestions}
              />
            ))}
          </Stack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

type Evidence = NonNullable<
  NonNullable<
    GetAiSuggestionsForControlEvidencesQuery['ai_evidence_suggestions_for_control']
  >['evidences']
>[number];

type SuggestionItem = {
  controlId: string;
  evidence: Evidence;
  refetchSuggestions: () => Promise<void>;
};

export const AiSuggestionItem = ({ evidence, controlId, refetchSuggestions }: SuggestionItem) => {
  const { t } = useTranslation();
  const borderColor = useColorModeValue('gray.100', 'gray.500');
  const textColor = useColorModeValue('gray.600', 'gray.300');
  /* Safe to assert because evidence allways fallback to Pending status if no control is linked */
  const evidenceStatus = evidence.status as EvidenceStatus;

  const [linkEvidenceToControl, { isLoading: isLinkingEvidence }] =
    useLinkEvidenceToControlMutation();

  const handleLinkEvidence = async () => {
    try {
      await linkEvidenceToControl({
        input: [
          {
            control_id: controlId,
            evidence_id: evidence.id,
          },
        ],
      }).unwrap();

      await refetchSuggestions();
    } catch (error) {
      errorToast(t('errorMessages.linkFailed', { entity: t('entities.evidence') }));
      datadogLogs.logger.error(
        'Failed linking suggested evidence to control',
        { controlId, evidenceId: evidence.id },
        toError(error),
      );
    }
  };

  return (
    <Box border="1px" rounded="md" borderColor={borderColor} p={5}>
      <Flex justifyContent="space-between">
        <Stack fontSize="xs" spacing={2}>
          <Flex gap={2}>
            <Tag colorScheme="purple">
              {t('entities.evidence')} / {evidence.internal_id}
            </Tag>
            <StatusTag
              size="sm"
              minW="auto"
              maxW="none"
              colorScheme={EVIDENCE_STATUS_COLOR[evidenceStatus]}
            >
              {t(`evidences.status.${evidenceStatus}`)}
            </StatusTag>
          </Flex>

          <OverflowContainer>
            <OverflowContainer.Tooltip
              label={evidence.name}
              placement="top"
              gutter={12}
              openDelay={500}
            >
              <Link
                as={RouterLink}
                noOfLines={1}
                fontWeight={600}
                color={textColor}
                search={{
                  drawerEntity: 'evidence',
                  drawerEntityId: evidence.id,
                }}
              >
                {evidence.name}
              </Link>
            </OverflowContainer.Tooltip>
          </OverflowContainer>

          <Text color={textColor}>{evidence.description}</Text>
        </Stack>
        <IconButton
          size="sm"
          variant="outline"
          aria-label="Link"
          icon={<Icon as={PlusIcon} />}
          onClick={handleLinkEvidence}
          isLoading={isLinkingEvidence}
        />
      </Flex>
    </Box>
  );
};
