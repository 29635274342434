import { EvidenceIntegrationType } from '@main/graphql/client-scalars';
import { Integration_Names_Enum } from '@main/graphql/types.generated';
import { IntegrationConfigSchemas } from '@main/integrations/shared';
import { z } from 'zod';

declare module '@main/integrations/shared' {
  interface IntegrationHandlerConfig {
    [Integration_Names_Enum.Crowdstrike]: HandlerConfigCrowdstrike;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-object-type, @typescript-eslint/no-empty-interface
  interface HandlerConfigCrowdstrike {}
}

export const CrowdstrikeConfigSchemas: IntegrationConfigSchemas<Integration_Names_Enum.Crowdstrike> =
  {
    name: Integration_Names_Enum.Crowdstrike,

    orgConfig: z.object({
      baseUrl: z.string().url().describe('API Base URL'),
      clientId: z.string().min(1).describe('Client ID'),
      clientSecret: z.string().min(1).describe('Client Secret'),
    }),

    evidenceConfig: z.discriminatedUnion('type', [
      z.object({
        type: z.literal(EvidenceIntegrationType.AntiMalware),
      }),

      z.object({
        type: z.literal(EvidenceIntegrationType.RemovableMedia),
        requiredPolicyIds: z.array(z.string()).optional(),
        excludeHosts: z.array(z.string()).optional(),
      }),
    ]),
  };
