import { Flex, Stack, Tag, Text, useColorModeValue } from '@chakra-ui/react';
import { UserIcon } from '@heroicons/react/24/outline';
import { useAiAutocompleteEntityMutation } from '@main/graphql/features/AiAutocompleteEntity.generated';
import { useUpdateControlByIdMutation } from '@main/graphql/mutations/UpdateControlById.generated';
import { Control_Priorities_Enum, Field_Entities_Enum } from '@main/graphql/types.generated';
import {
  DrawerProperty,
  EditableAutoResizeTextarea,
  EditableAvatar,
  EditableTag,
  HoverAskAiButton,
} from '@main/ui';
import { BarsIcon, DoubleTagsIcon, TagIcon } from '@main/ui/icons';
import { TFunction } from 'i18next';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { CustomFieldInput } from '../custom-fields/custom-field-input';
import { useGetFieldConfigsQuery } from '../custom-fields/field-config';
import { useUpdateControlOnDrawerHandler } from '../program/use-update-control-handler';
import { getControlFrequencyOption, getControlFrequencyOptions } from '../shared/frequency';
import { getAssigneeOptions } from '../shared/get-assignee-options';
import {
  getCurrentOrgNonDisabledUsers,
  getCurrentOrgUsersMap,
  getCurrentUserSelectedOrg,
  getCurrentUserSelectedOrgId,
  getCurrentUserSelectedOrgRole,
} from '../user/slice';
import { getMappedControl } from './slice';
import { CategoryTags, CriteriaTags, CustomTags, GroupTags } from './tags';

type ControlDetailsProps = {
  controlId: string;
};

const priority = Object.values(Control_Priorities_Enum);

function getPriorityOptions(t: TFunction) {
  const priorityOptions = priority.map((value) => ({
    value,
    label: t(`controls.priority.enums.${value}`),
    colorScheme: 'purple',
  }));

  return priorityOptions;
}

export const ControlDetailsTab: FC<ControlDetailsProps> = ({ controlId }) => {
  const { t } = useTranslation();
  const textColor = useColorModeValue('gray.600', 'gray.500');
  const orgId = useAppSelector(getCurrentUserSelectedOrgId);
  const currentOrgUsers = useAppSelector(getCurrentOrgUsersMap);
  const currentOrgNonDisabledUsers = useAppSelector(getCurrentOrgNonDisabledUsers);
  const { is_day_zero_ai_features_enabled } = useAppSelector(getCurrentUserSelectedOrg);

  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canEditControl = userRole.permissionMap?.update_controls;

  const updateControlHandler = useUpdateControlOnDrawerHandler();
  const [updateControlById] = useUpdateControlByIdMutation();
  const control = useAppSelector((state) => getMappedControl(state, controlId));
  const fieldConfigsQuery = useGetFieldConfigsQuery({
    orgId,
    entityName: Field_Entities_Enum.Control,
  });

  const filteredConfigs = useMemo(() => {
    return fieldConfigsQuery.data?.field_configs.filter((config) => {
      if (config.program_field_configs.length === 0) {
        return true;
      }

      return control?.programs.some((program) =>
        config.program_field_configs.some((fieldConfig) => fieldConfig.program_id === program.id),
      );
    });
  }, [fieldConfigsQuery.data, control?.programs]);

  const [aiAutocompleteEntity] = useAiAutocompleteEntityMutation();

  const getAssigneeValue = () => {
    if (!control?.assignee_id) {
      return;
    }

    const user = currentOrgUsers[control.assignee_id];
    if (!user) {
      return;
    }

    return {
      id: control?.assignee_id,
      displayName: user.displayName,
    };
  };
  return (
    <Stack spacing={[6, 3]}>
      <DrawerProperty isReadOnly={!canEditControl}>
        <DrawerProperty.Label icon={BarsIcon}>{t('controls.description')}</DrawerProperty.Label>
        <DrawerProperty.Content {...HoverAskAiButton.ParentProps}>
          <EditableAutoResizeTextarea
            defaultValue={control?.description}
            placeholder={
              canEditControl
                ? t('controls.placeholder.description')
                : t('controls.hasNoPermission', {
                    property: t('controls.description').toLowerCase(),
                  })
            }
            color={textColor}
            onSubmit={(value) =>
              updateControlHandler(
                updateControlById({
                  controlId,
                  updatePayload: {
                    description: value,
                  },
                }),
              )
            }
          />
          {is_day_zero_ai_features_enabled && canEditControl && (
            <HoverAskAiButton
              data-testid="autocomplete-control-description"
              renderRecommendation={(description) => (
                <Text data-testid="control-description-recommendation">{description}</Text>
              )}
              onRecommendationRequest={() =>
                aiAutocompleteEntity({
                  input: { entity: 'control-description', entityId: controlId },
                })
                  .unwrap()
                  .then((res) => res.ai_autocomplete_entity?.autocomplete)
              }
              onRecommendationAccept={(description) =>
                updateControlHandler(
                  updateControlById({ controlId, updatePayload: { description } }),
                )
              }
            >
              {control?.description
                ? t('askAiButton.regenerateLabel')
                : t('askAiButton.generateLabel')}
            </HoverAskAiButton>
          )}
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty isReadOnly={!canEditControl}>
        <DrawerProperty.Label icon={TagIcon}>
          {t('controls.table.columns.group')}
        </DrawerProperty.Label>
        <DrawerProperty.Content>
          <GroupTags controlId={controlId} />
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty isReadOnly={!canEditControl}>
        <DrawerProperty.Label icon={DoubleTagsIcon}>{t('controls.category')}</DrawerProperty.Label>
        <DrawerProperty.Content>
          <CategoryTags controlId={controlId} />
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty isReadOnly={!canEditControl}>
        <DrawerProperty.Label icon={DoubleTagsIcon}>{t('controls.criteria')}</DrawerProperty.Label>
        <DrawerProperty.Content>
          <CriteriaTags controlId={controlId} />
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty isReadOnly={!canEditControl}>
        <DrawerProperty.Label icon={DoubleTagsIcon}>{t('controls.tags')}</DrawerProperty.Label>
        <DrawerProperty.Content>
          <CustomTags controlId={controlId} />
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty isReadOnly={!canEditControl}>
        <DrawerProperty.Label icon={TagIcon}>{t('controls.frequency.label')}</DrawerProperty.Label>
        <DrawerProperty.Content>
          <EditableTag
            isClearable={false}
            value={control?.frequency && getControlFrequencyOption(t, control.frequency)}
            options={getControlFrequencyOptions(t)}
            onChange={(option) => {
              if (option) {
                updateControlHandler(
                  updateControlById({
                    controlId,
                    updatePayload: {
                      frequency: option.value,
                    },
                  }),
                );
              }
            }}
          />
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty isReadOnly={!canEditControl}>
        <DrawerProperty.Label icon={TagIcon}>{t('controls.priority.label')}</DrawerProperty.Label>
        <DrawerProperty.Content>
          <EditableTag
            value={
              control?.priority && {
                value: control?.priority,
                label: t(`controls.priority.enums.${control.priority}`),
                colorScheme: 'purple',
              }
            }
            options={getPriorityOptions(t)}
            placeholder={
              canEditControl
                ? t('controls.placeholder.priority')
                : t('controls.hasNoPermission', {
                    property: t('controls.priority.label').toLowerCase(),
                  })
            }
            onChange={(option) => {
              updateControlHandler(
                updateControlById({
                  controlId,
                  updatePayload: {
                    priority: option?.value ?? null,
                  },
                }),
              );
            }}
          />
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty isReadOnly={!canEditControl}>
        <DrawerProperty.Label icon={UserIcon}>{t('controls.owner')}</DrawerProperty.Label>
        <DrawerProperty.Content>
          <EditableAvatar
            options={getAssigneeOptions(currentOrgNonDisabledUsers)}
            value={getAssigneeValue()}
            placeholder={
              canEditControl
                ? t('controls.placeholder.owner')
                : t('controls.hasNoPermission', {
                    property: t('controls.owner').toLowerCase(),
                  })
            }
            onChange={(option) => {
              if (!option) {
                updateControlHandler(
                  updateControlById({
                    controlId,
                    updatePayload: {
                      assignee_id: null,
                    },
                  }),
                );
                return;
              }

              updateControlHandler(
                updateControlById({
                  controlId,
                  updatePayload: {
                    assignee_id: option.id,
                  },
                }),
              );
            }}
          />
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty>
        <DrawerProperty.Label icon={DoubleTagsIcon}>
          {t('controls.table.columns.programs')}
        </DrawerProperty.Label>
        <DrawerProperty.Content px={2} display="flex" alignItems="center">
          {control?.programs.length === 0 ? (
            <Text fontSize="xs" textColor="gray.500">
              {t('controls.noAssosiatedPrograms')}
            </Text>
          ) : (
            <Flex flexWrap="wrap" gap={2} h="full" alignItems="center">
              {control?.programs.map((program) => (
                <Tag key={program.id} colorScheme={program.colorScheme}>
                  {program.name}
                </Tag>
              ))}
            </Flex>
          )}
        </DrawerProperty.Content>
      </DrawerProperty>

      {filteredConfigs?.map((config) => (
        <CustomFieldInput
          key={config.id}
          entityId={controlId}
          config={config}
          values={control?.field_values}
          onChange={fieldConfigsQuery.refetch}
          isReadOnly={!canEditControl}
        />
      ))}
    </Stack>
  );
};
