/*  eslint-disable @typescript-eslint/no-unsafe-member-access  */
/*  eslint-disable @typescript-eslint/no-unsafe-assignment  */
/*  eslint-disable @typescript-eslint/no-unsafe-return  */
/*  eslint-disable @typescript-eslint/no-unsafe-call  */
import { RequiredGraphqlState } from '@main/graphql/shared-api';
import type { NhostClient } from '@nhost/nhost-js';

/**
 * This patch would not be needed if we upgrade the nhost sdk
 * https://github.com/nhost/nhost/blob/2dd4df51708dddcd643f5100b4ff7c8666a262cb/docs/reference/javascript/nhost-js/set-role.mdx#L12
 */
export function patchNhostStorageHeaders(
  nhost: NhostClient,
  role: RequiredGraphqlState['defaultRole'],
): void {
  const storage = nhost.storage;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: Accessing private property 'api'
  const originalGenerateAuthHeaders = storage.api['generateAuthHeaders'];

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: Accessing private property 'api'
  storage.api['generateAuthHeaders'] = function () {
    const originalHeaders =
      typeof originalGenerateAuthHeaders === 'function'
        ? originalGenerateAuthHeaders.call(this)
        : {};

    return {
      ...originalHeaders,
      'x-hasura-role': role,
    };
  };
}
