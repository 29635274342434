import { Finding_Causes_Enum } from '@main/graphql/types.generated';

import { ControlFinding } from '../control-finding';
import { ContentEvidenceStatus } from './content-evidence-status';
import { ContentGeneric } from './content-generic';
import { ContentIntegrationCheck } from './content-integration-check';
import { ContentIntegrationError } from './content-integration-error';
import { ContentManual } from './content-manual';
import { ContentPolicyError } from './content-policy-error';

export const ControlFindingContent = (props: ControlFinding) => {
  switch (props.cause) {
    case Finding_Causes_Enum.AiRecommendation:
      return <ContentGeneric {...props} />;
    case Finding_Causes_Enum.AtRiskEvidence:
    case Finding_Causes_Enum.ExpiredEvidence:
    case Finding_Causes_Enum.MissingEvidence:
    case Finding_Causes_Enum.FutureEvidenceValidity:
      return <ContentEvidenceStatus {...props} />;
    case Finding_Causes_Enum.IntegrationError:
      return <ContentIntegrationError {...props} />;
    case Finding_Causes_Enum.IntegrationCheck:
      return <ContentIntegrationCheck {...props} />;
    case Finding_Causes_Enum.PolicyError:
      return <ContentPolicyError {...props} />;
    case Finding_Causes_Enum.ManualIssue:
    case Finding_Causes_Enum.ManualRecommendation:
    case Finding_Causes_Enum.ManualConsideration:
      return <ContentManual {...props} />;
  }
};
