import { Integration_Names_Enum } from '@main/graphql/types.generated';
import { IntegrationConfigSchemas } from '@main/integrations/shared';
import { z } from 'zod';

declare module '@main/integrations/shared' {
  interface IntegrationHandlerConfig {
    [Integration_Names_Enum.Shortcut]: HandlerConfigShortcut;
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type, @typescript-eslint/no-empty-interface
  interface HandlerConfigShortcut {}
}

export const ShortcutConfigSchemas: IntegrationConfigSchemas<Integration_Names_Enum.Shortcut> = {
  name: Integration_Names_Enum.Shortcut,

  orgConfig: z.object({
    apiToken: z.string().min(1).describe('API Token'),
  }),

  evidenceConfig: z.never(),
};
