import { Text, useColorModeValue } from '@chakra-ui/react';
import { ReactNode } from 'react';

export function AssigneeAndMentionContent({
  assignor,
  internalId,
  entityName,
  content,
}: {
  assignor: string;
  internalId: string;
  entityName: string;
  content: string;
}) {
  const notificationTextColor = useColorModeValue('gray.600', 'gray.300');

  return (
    <Text fontSize="sm" textColor={notificationTextColor}>
      <Text as="b">{assignor}</Text> {content}
      <Text as="b">
        {' '}
        {internalId} - {entityName}
      </Text>
    </Text>
  );
}

export function StatusUpdateContent({
  internalId,
  entityName,
  content,
}: {
  internalId: string;
  entityName: string;
  content: string;
}) {
  const notificationTextColor = useColorModeValue('gray.600', 'gray.300');

  return (
    <Text fontSize="sm" textColor={notificationTextColor}>
      {content}:{' '}
      <Text as="b">
        {internalId} - {entityName}
      </Text>
    </Text>
  );
}

export function TaskStatusCompletedContent({ content }: { content: ReactNode }) {
  const notificationTextColor = useColorModeValue('gray.600', 'gray.300');

  return (
    <Text fontSize="sm" textColor={notificationTextColor}>
      {content}
    </Text>
  );
}
