import { Stack, Tag, useColorModeValue } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { UserIcon } from '@heroicons/react/24/outline';
import { useUpdateQuestionnaireMutation } from '@main/graphql/mutations/UpdateQuestionnaire.generated';
import { useGetQuestionnaireSubscription } from '@main/graphql/subscriptions/GetQuestionnaireSubscription';
import {
  getQuestionnaireType,
  QUESTIONNAIRE_TYPES,
  QuestionnaireType,
  toError,
} from '@main/shared/utils';
import {
  DrawerProperty,
  EditableAutoResizeTextarea,
  EditableAvatar,
  errorToast,
  FileUpload,
  useDownloadStorageFile,
  useEagerFileUpload,
} from '@main/ui';
import { BarsIcon, TagIcon } from '@main/ui/icons';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getAssigneeOptions } from '../shared/get-assignee-options';
import {
  getCurrentOrgNonDisabledUsers,
  getCurrentOrgUsersMap,
  getCurrentUserSelectedOrg,
  getCurrentUserSelectedOrgRole,
} from '../user/slice';
import { useUpdateQuestionnaireHandler } from './use-update-questionnaire-handler';

export const QuestionnaireDetailsTab = ({ questionnaireId }: { questionnaireId: string }) => {
  const { t } = useTranslation();
  const textColor = useColorModeValue('gray.600', 'gray.500');
  const currentOrgUsers = useAppSelector(getCurrentOrgUsersMap);
  const currentOrgNonDisabledUsers = useAppSelector(getCurrentOrgNonDisabledUsers);
  const { id: organizationId } = useAppSelector(getCurrentUserSelectedOrg);
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canUpdateVendors = userRole.permissionMap?.write_vendors;

  const { data: questionnaireData } = useGetQuestionnaireSubscription({ id: questionnaireId });

  const [updateQuestionnaire] = useUpdateQuestionnaireMutation();
  const updateQuestionnaireHandler = useUpdateQuestionnaireHandler({
    questionnaireId,
    organizationId,
  });

  const questionnaire = questionnaireData?.questionnaires_by_pk;

  const getAssigneeValue = () => {
    if (!questionnaire?.owner_id) {
      return;
    }

    const user = currentOrgUsers[questionnaire.owner_id];
    if (!user) {
      return;
    }

    return {
      id: questionnaire.owner_id,
      displayName: user.displayName,
    };
  };

  const fileUpload = useEagerFileUpload({
    file: questionnaire?.file,
    onFileDownload: useDownloadStorageFile(),
    onFileUpload: async ({ error, file }) => {
      if (!questionnaireId) {
        return;
      }

      if (error) {
        errorToast(t('errorMessages.uploadFailed', { entity: t('entities.questionnaire') }));
        datadogLogs.logger.error(
          'Failed uploading questionnaire file',
          { questionnaireId },
          toError(error),
        );
        return;
      }

      await updateQuestionnaireHandler(
        updateQuestionnaire({
          id: questionnaireId,
          updatePayload: {
            file_id: file.id,
          },
        }),
      );
    },
    onFileDelete: async () => {
      if (!questionnaireId) {
        return;
      }

      await updateQuestionnaireHandler(
        updateQuestionnaire({
          id: questionnaireId,
          updatePayload: {
            file_id: null,
          },
        }),
      );
    },
  });

  if (!questionnaire?.id) {
    return null;
  }

  return (
    <Stack spacing={[6, 3]}>
      <DrawerProperty isReadOnly={!canUpdateVendors}>
        <DrawerProperty.Label
          icon={BarsIcon}
          tooltip={t('questionnaires.drawer.descriptionTooltip')}
        >
          {t('questionnaires.drawer.description')}
        </DrawerProperty.Label>
        <DrawerProperty.Content>
          <EditableAutoResizeTextarea
            defaultValue={questionnaire.description}
            placeholder={
              canUpdateVendors
                ? t('questionnaires.drawer.descriptionPlaceholder')
                : t('questionnaires.hasNoPermission', {
                    property: t('questionnaires.drawer.description').toLowerCase(),
                  })
            }
            color={textColor}
            onSubmit={(value) =>
              updateQuestionnaireHandler(
                updateQuestionnaire({
                  id: questionnaireId,
                  updatePayload: {
                    description: value,
                  },
                }),
              )
            }
          />
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty label={t('questionnaires.drawer.type')}>
        <DrawerProperty.Label icon={TagIcon}>
          {t('questionnaires.drawer.type')}
        </DrawerProperty.Label>
        <DrawerProperty.Content display="flex" alignItems="center" px={2}>
          <Tag colorScheme="purple" w="fit-content">
            {t(QUESTIONNAIRE_TYPES[getQuestionnaireType(questionnaire)].value)}
          </Tag>
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty isReadOnly={!canUpdateVendors}>
        <DrawerProperty.Label icon={UserIcon}>
          {t('questionnaires.drawer.owner')}
        </DrawerProperty.Label>
        <DrawerProperty.Content>
          <EditableAvatar
            isClearable={false}
            options={getAssigneeOptions(currentOrgNonDisabledUsers)}
            value={getAssigneeValue()}
            onChange={(option) => {
              if (!option) {
                return;
              }

              updateQuestionnaireHandler(
                updateQuestionnaire({
                  id: questionnaireId,
                  updatePayload: {
                    owner_id: option.id,
                  },
                }),
              );
            }}
          />
        </DrawerProperty.Content>
      </DrawerProperty>

      {getQuestionnaireType(questionnaire) === QuestionnaireType.File && (
        <DrawerProperty isReadOnly={!canUpdateVendors}>
          <DrawerProperty.Label icon={TagIcon}>
            {t('questionnaires.drawer.file')}
          </DrawerProperty.Label>
          <DrawerProperty.Content w="full" display="flex" px={2}>
            <FileUpload {...fileUpload}>
              <FileUpload.Dropzone />
            </FileUpload>
          </DrawerProperty.Content>
        </DrawerProperty>
      )}
    </Stack>
  );
};
